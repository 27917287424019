import { ISupplier } from "models/index";
import { actions } from "redux/reducers/suppliers/reducer";
import { ReduxStoreService } from "services/redux";

/**
 * Class for managing the basics of the Root Supplier data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class SupplierService extends ReduxStoreService<ISupplier> {
	constructor() {
		super("suppliers", actions.setList, actions.setQueried);
	}
}

export { SupplierService };
