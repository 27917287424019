import OpenAI from "openai";

/**
 * Possible OpenAI Model Engine versions.
 */
export enum OpenAIModelVersion {
	GPT3_TURBO = "gpt-3.5-turbo",
	GPT3_16K = "gpt-3.5-turbo-16k",
	GPT3_1106 = "gpt-3.5-turbo-1106",
	GPT4 = "gpt-4",
	GPT4_0613 = "gpt-4-0613",
	GPT4_VISION = "gpt-4-vision-preview",
	DAVINCI_TEXT = "davinci-002"
}

/**
 * OpenAI API internal Singleton
 * TODO: Review the use of dangerouslyAllowBrowser.
 * https://help.openai.com/en/articles/5112595-best-practices-for-api-key-safety
 */
const OpenAIService = new OpenAI({
	apiKey: process.env.REACT_APP_OPENAI_KEY ?? "",
	dangerouslyAllowBrowser: true
});

export { OpenAIService };
