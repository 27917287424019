import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";

/**
 * Contract for typing each Company information within the system.
 */
export interface ICompany extends IFirebaseItem {
	id: string;
	name: string;
	createdAt: Date;
	updatedAt: Date;
}

/**
 * Company Redux slice interface.
 */
export interface ICompanySlice extends ISliceList<ICompany> {}

/**
 * System-level Company factory class.
 */
export class Company implements ICompany {
	id: string = "";
	name: string = "";
	createdAt: Date = new Date();
	updatedAt: Date = new Date();
	timestamp: Timestamp;
	deleted: boolean;

	constructor(data?: undefined | ICompany) {
		this.id = data?.id ?? "";
		this.name = data?.name ?? "";
		this.createdAt = data?.createdAt ?? new Date();
		this.updatedAt = data?.updatedAt ?? null;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.deleted = data?.deleted ?? false;
	}
}

/**
 * Companies initial state.
 */
export const companiesInitialState: ICompanySlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false
	// error: ""
};
