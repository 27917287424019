// Fluent UI icons

export enum PaymentTypes {
	Cash = "cash",
	CreditCard = "creditCard",
	DebitCard = "debitCard",
	Pix = "pix"
}

export const PaymentTypeDetails = {
	// [PaymentTypes.Cash]: {
	// 	id: PaymentTypes.Cash,
	// 	name: "Dinheiro",
	// 	icon: "Money"
	// },
	[PaymentTypes.Pix]: {
		id: PaymentTypes.Pix,
		name: "Pix",
		icon: "Money"
	},
	[PaymentTypes.DebitCard]: {
		id: PaymentTypes.DebitCard,
		name: "Cartão de Débito",
		icon: "PaymentCard"
	},
	[PaymentTypes.CreditCard]: {
		id: PaymentTypes.CreditCard,
		name: "Cartão de Crédito",
		icon: "PaymentCard"
	}
};
