import { IOption } from "components/shared/dropdown/Dropdown";
import { ICategory } from "models/index";
import { RootState } from "redux/store";
import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";

/**
 * Selectors: Category.
 */
export const CategorySelectors = {
	getCategories: (state: RootState) => state.categories.list,
	getCategoriesDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.categories.list?.map((category: ICategory) => ({
				text: category.name,
				key: category.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getCategoryById: (state: RootState, id: string) =>
		state.categories.list.find((item: ICategory) => item.id === id),
	getCategoriesLoading: (state: RootState) => state.categories.loading,
	getCategoriesQueried: (state: RootState) => state.categories.queried,
	getIsEditing: (state: RootState) => state.categories.editing,
	getCategoriesEditing: (state: RootState) => state.categories.editingList
};
