// TODO: Create data selectors

import { RootState } from "redux/store";
import {
	IUserApplicationRoles,
	IUserProfile,
	IUserProfileForm,
	IUserProfileSlice
} from "models";

// 1. Define your interfaces, that helps know your data and create better data flows / processes

/**
 * Contract for defining the structure of selectors
 * allowed in the UserProfile Slice of the application state tree.
 */
interface IUserSelectors {
	select: (state: RootState) => IUserProfileSlice;
	selectUserProfile: (state: RootState) => IUserProfile;
	selectUserProfileActiveCompany: (state: RootState) => string;
	selectUserProfileCompanies: (state: RootState) => string[];
	selectUserProfileRoles: (state: RootState) => IUserApplicationRoles;
	selectUserProfileName: (state: RootState) => string;
	selectUserProfileNameWithAnon: (state: RootState) => string;
	selectUserProfileDisplayName: (state: RootState) => string;
	selectUserProfileId: (state: RootState) => string;
	selectAuthenticating: (state: RootState) => boolean;
	selectAuthenticated: (state: RootState) => boolean;
	selectUserProfilePictureURL: (state: RootState) => string;
	selectUserProfileForm: (state: RootState) => IUserProfileForm;
	selectUserProfileFormOpen: (state: RootState) => boolean;
	selectUserProfileFormEditing: (state: RootState) => boolean;
}

// 2. Define the functionalities that implement necessary logic to achieve contract definitions

/**
 * Selects the whole slice of UserProfile from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function select(state: RootState): IUserProfileSlice {
	return state.user;
}

/**
 * Selects the UserProfile from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfile(state: RootState): IUserProfile {
	return state.user.profile;
}

/**
 * Selects the UserProfile Active Company from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileActiveCompany(state: RootState): string {
	return state.user.profile.activeCompany;
}

/**
 * Selects the UserProfile Companies from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileCompanies(state: RootState): string[] {
	return state.user.profile.companies;
}

/**
 * Selects the UserProfile Roles from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileRoles(state: RootState): IUserApplicationRoles {
	return state.user.profile.roles;
}

/**
 * Selects the UserProfile Name from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileName(state: RootState): string {
	return state.user.profile.name;
}

/**
 * Selects the UserProfile Name from the app state tree, returns an "Anon" if no user is authenticated.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileNameWithAnon(state: RootState): string {
	return state.user.profile.name ?? "Anonymous";
}

/**
 * Selects the UserProfile Display Name from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileDisplayName(state: RootState): string {
	return state.user.profile.displayName;
}

/**
 * Selects the UserProfile ID from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileId(state: RootState): string {
	return state.user.profile.id;
}

/**
 * Selects the UserProfile is authenticating flag from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectAuthenticating(state: RootState): boolean {
	return state.user.authenticating;
}

/**
 * Selects the UserProfile is authenticating flag from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectAuthenticated(state: RootState): boolean {
	return state.user.authenticated;
}

/**
 * Selects the UserProfile picture URL from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfilePictureURL(state: RootState): string {
	return state.user.profile.pictureURL;
}

/**
 * Selects the UserProfile form from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileForm(state: RootState): IUserProfileForm {
	return state.user.form;
}

/**
 * Selects the UserProfile form open flag from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileFormOpen(state: RootState): boolean {
	return state.user.form.isOpen;
}

/**
 * Selects the UserProfile form editing flag from the app state tree.
 *
 * @param state The application root state, as per definition
 */
function selectUserProfileFormEditing(state: RootState): boolean {
	return state.user.form.isEditingInfo;
}

// 3. Create the object which groups the selectors usable for picking data from UserProfile slice.

/**
 * Selector group for UserProfile slice of the app.
 */
const UserSelectors: IUserSelectors = {
	select,
	selectUserProfile,
	selectUserProfileActiveCompany,
	selectUserProfileCompanies,
	selectUserProfileRoles,
	selectUserProfileName,
	selectUserProfileDisplayName,
	selectUserProfileNameWithAnon,
	selectUserProfileId,
	selectAuthenticating,
	selectAuthenticated,
	selectUserProfilePictureURL,
	selectUserProfileForm,
	selectUserProfileFormOpen,
	selectUserProfileFormEditing
};

export { UserSelectors };
