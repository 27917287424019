import { Timestamp } from "firebase/firestore";
import { ISliceList } from "interfaces/slices";
import { IFirebaseItem } from "interfaces/index";

/**
 * LandingPage basic interface.
 */
interface ILandingMail extends IFirebaseItem {
	email: string;
	verified?: boolean;
	verifiedOn?: string | Timestamp;
}

/**
 * LandingPage Redux slice interface.
 */
interface ILandingPageSlice extends ISliceList<ILandingMail> {}

/**
 * LandingPage factory class.
 */
class LandingMail implements ILandingMail {
	id: string = "";
	email: string = "";
	verified?: boolean;
	verifiedOn?: string | Timestamp;
	deleted: boolean = false;
	timestamp: Timestamp;
	lastChangedBy: string = "";

	constructor(data?: undefined | ILandingMail) {
		this.id = data?.id ?? "";
		this.email = data?.email ?? "";
		this.verified = data?.verified ?? false;
		this.verifiedOn = data?.verifiedOn ?? "";
		this.deleted = data?.deleted ?? false;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.lastChangedBy = data?.lastChangedBy ?? "";
	}
}

/**
 * User profile initial state.
 */
const landingPageInitialState: ILandingPageSlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false
	// error: ""
};

export {
	ILandingMail,
	LandingMail,
	ILandingPageSlice,
	landingPageInitialState
};
