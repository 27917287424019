import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";
import { IOption } from "components/shared/dropdown";

import { ISupplier } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Supplier.
 */
export const SupplierSelectors = {
	getSuppliers: (state: RootState) => state.suppliers.list,
	getSuppliersDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.suppliers.list?.map((supplier: ISupplier) => ({
				text: supplier.name,
				key: supplier.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getSupplierById: (state: RootState, id: string) =>
		state.suppliers.list.find((supplier: ISupplier) => supplier.id === id),
	getSuppliersLoading: (state: RootState) => state.suppliers.loading,
	getSuppliersQueried: (state: RootState) => state.suppliers.queried,
	getIsEditing: (state: RootState) => state.suppliers.editing,
	getSuppliersEditing: (state: RootState) => state.suppliers.editingList
	// getSuppliersError: (state: RootState) => state.suppliers.error
};
