import { createSelector } from "@reduxjs/toolkit";
import { ICompany } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Company.
 */
export const CompanySelectors = {
	getCompanies: (state: RootState) => state.companies.list,
	getCompanyByIds: createSelector(
		(state: RootState, itemIds: string[]) => itemIds,
		(state: RootState) => state.companies.list,
		(itemIds, companies: ICompany[]) =>
			companies.filter((company) => itemIds.includes(company.id))
	),
	getCompanyById: createSelector(
		(state: RootState, itemId: string) => itemId,
		(state: RootState) => state.companies.list,
		(itemId, companies: ICompany[]) =>
			companies.find((company) => company.id === itemId)
	),
	getCompaniesLoading: (state: RootState) => state.companies.loading,
	getCompaniesQueried: (state: RootState) => state.companies.queried,
	getIsEditing: (state: RootState) => state.companies.editing,
	getCompaniesEditing: (state: RootState) => state.companies.editingList
	// getCompaniesError: (state: RootState) => state.companies.error
};
