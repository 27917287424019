import { store } from "App";
import { Unsubscribe } from "firebase/auth";
import { IFirebaseItem } from "interfaces/index";
import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";
import { StoreType } from "types";

/**
 * Class for abstracting the basics of dealing with Redux store's data.
 */
class ReduxStoreService<
	T extends IFirebaseItem
> extends FirebaseServiceHandler<T> {
	private _store: StoreType;
	private _setListAction: (items: T[]) => any;
	private _setQueriedAction: (status: boolean) => any;

	constructor(
		collectionName: string,
		setListAction: (items: T[]) => any,
		setQueriedAction: (status: boolean) => any,
		_store: undefined | StoreType = store
	) {
		super(collectionName);
		this._store = _store;
		this._setListAction = setListAction;
		this._setQueriedAction = setQueriedAction;
	}

	/**
	 * Gets all the items in the collection.
	 *
	 * @returns A promise with the items.
	 */
	async getAll(): Promise<T[]> {
		const items = await super.getItems();
		this.setList(items);
		this.setQueried(true);

		return items;
	}

	/**
	 * Gets all the items in the collection, and subscribes to changes.
	 *
	 * @param onReady Callback to be called when the items are ready.
	 * @returns Unsubscribe function, as promised.
	 */
	async getAllLive(
		onReady?: undefined | ((items: T[]) => void)
	): Promise<Unsubscribe> {
		const self = this;

		function onReadyWrapper(items: T[]): void {
			if (typeof onReady === "function") onReady(items);
			self.setList(items);
			self.setQueried(true);
		}

		return super.getItemsLive(onReadyWrapper);
	}

	/**
	 * Sets a list of items at the slice state.
	 *
	 * @param items The items to be set at the .list property of the slice.
	 *
	 * @returns A promise with the item.
	 */
	async setList(items: T[]): Promise<void> {
		this._store.dispatch(this._setListAction(items));
	}

	/**
	 * Sets the queried status at the slice state.
	 *
	 * @param status The status to be set at the .queried property of the slice.
	 */
	async setQueried(status: boolean): Promise<void> {
		this._store.dispatch(this._setQueriedAction(status));
	}

	/**
	 * Resets the list of items and .queried state.
	 */
	async reset(): Promise<void> {
		this._store.dispatch(this._setListAction([]));
		this._store.dispatch(this._setQueriedAction(false));
	}

	/**
	 * Creates an item and adds it to the collection.
	 * Stores in the Redux store the new list of items.
	 *
	 * @param item The item to be created.
	 * @param onCreated [Optional] Callback to be called when the item is created.
	 */
	async create(
		item: T,
		onCreated: (createdItem: T) => Promise<void> = null
	): Promise<void> {
		const _getItems = () => super.getItems();

		this.createItem(item, (item) => {
			// const storeState = store.getState();
			_getItems().then((items) => {
				this._store.dispatch(this._setListAction(items));
			});

			if (typeof onCreated === "function") onCreated(item);
		});
	}

	/**
	 * Wraps the Redux dispatch method.
	 *
	 * @param action The action to be dispatched
	 */
	async dispatch(action: () => any): Promise<void> {
		this._store.dispatch(action());
	}

	// async getItemsWithRedux(): Promise<T[]> {
	// 	const items = await super.getItems();
	// 	this._store.dispatch(this._setListAction(items));
	// 	this._store.dispatch(this._setQueriedAction(true));
	// 	return items;
	// }

	// Other Redux methods can be added similarly...
}

export { ReduxStoreService };
