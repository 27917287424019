import React from "react";

import { IInputBaseProps, InputBase } from "./InputBase";

/**
 * InputCheckbox props contract interface.
 */
interface IInputCheckboxProps extends IInputBaseProps {}

/**
 * InputCheckbox shared component.
 *
 * @param props IInputCheckboxProps
 * @returns
 */
function InputCheckbox(props: IInputCheckboxProps): JSX.Element {
	return (
		<InputBase
			type="checkbox"
			checked={props.checked}
			onChange={props.onChange}
			id={props.id ?? undefined}
			disabled={props.disabled ?? undefined}
			{...props}
		/>
	);
}

export { InputCheckbox, IInputCheckboxProps };
