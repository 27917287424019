import React from "react";
import { IconButton, TooltipHost } from "@fluentui/react";
import { IIconButtonProps } from "interfaces/index";

const iconProps = {
	iconName: "Delete"
};

/**
 * Re-usable Delete icon button component
 *
 * @param param0
 * @returns
 */
export function DeleteIconButton({
	onClick,
	className,
	tooltip = "Delete", // TODO Review text ref
	...otherProps
}: IIconButtonProps) {
	return (
		<TooltipHost
			title={tooltip}
			aria-label={tooltip}
			styles={{ root: { display: "inline-block" } }}
		>
			<IconButton
				iconProps={iconProps}
				onClick={onClick}
				className={`button icon delete ${className ?? ""}`}
				aria-label={tooltip}
				{...otherProps}
			/>
		</TooltipHost>
	);
}
