import { MessageModalType } from "enums";
import { IAppConfigSlice } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: AppConfig.
 */
export const AppConfigSelectors = {
	getAppBarHidden: (state: RootState): boolean =>
		state.appConfig.appBar.hidden,
	getAppBarTitle: (state: RootState): string => state.appConfig.appBar.title,
	getAppConfig: (state: RootState): IAppConfigSlice => state.appConfig,
	getAppConfigId: (state: RootState): string => state.appConfig.id,
	getLoading: (state: RootState): boolean => state.appConfig.loader.loading,
	getLoadingText: (state: RootState): string =>
		state.appConfig.loader.loadingText,
	getMessageModalType: (state: RootState): MessageModalType =>
		state.appConfig.messageModal.type,
	getMessageModalOpen: (state: RootState): boolean =>
		state.appConfig.messageModal.open,
	getMessageModalTitle: (state: RootState): string =>
		state.appConfig.messageModal.title,
	getMessageModalText: (state: RootState): string =>
		state.appConfig.messageModal.text
};
