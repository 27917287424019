import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";

import { IOption } from "components/shared/dropdown";
import { IAIModelProcess } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: AIModelProcess.
 */
export const AIModelProcessSelectors = {
	getAIModelProcesses: (state: RootState) => state.aiModelProcesses.list,
	getAIModelProcessesDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.aiModelProcesses.list?.map(
				(aiModelProcess: IAIModelProcess) => ({
					text: `${aiModelProcess.modelId} (PID: ${aiModelProcess.id})`,
					key: aiModelProcess.id
				})
			),
		(list: IDropdownOption<IOption>[]) => list
	),
	getBrandById: (state: RootState, id: string) =>
		state.aiModelProcesses.list?.find(
			(aiModelProcess: IAIModelProcess) => aiModelProcess.id === id
		),
	getAIModelProcessesLoading: (state: RootState) =>
		state.aiModelProcesses.loading,
	getAIModelProcessesQueried: (state: RootState) =>
		state.aiModelProcesses.queried,
	getIsEditing: (state: RootState) => state.aiModelProcesses.editing,
	getAIModelProcessesEditing: (state: RootState) =>
		state.aiModelProcesses.editingList
	// getAIModelProcessesError: (state: RootState) => state.aiModelProcesses.error
};
