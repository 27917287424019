import { Timestamp } from "firebase/firestore";
import { ISliceList } from "interfaces/slices";
import { IFirebaseItem } from "interfaces/index";

/**
 * Category basic interface.
 */
interface ICategory extends IFirebaseItem {
	name: string;
	description: string;
	image: string;
	parent: null | string;
	skuInitial: string;
}

/**
 * Category Redux slice interface.
 */
interface ICategorySlice extends ISliceList<ICategory> {}

/**
 * Category factory class.
 */
class Category implements ICategory {
	id: string = "";
	name: string = "";
	description: string = "";
	parent: null | string = null;
	image: string = "";
	deleted: boolean = false;
	timestamp: Timestamp;
	skuInitial: string = "";
	lastChangedBy: string = "";

	constructor(data?: undefined | ICategory) {
		this.id = data?.id ?? "";
		this.name = data?.name ?? "";
		this.description = data?.description ?? "";
		this.parent = data?.parent ?? "";
		this.image = data?.image ?? "";
		this.deleted = data?.deleted ?? false;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.skuInitial = data?.skuInitial ?? "";
		this.lastChangedBy = data?.lastChangedBy ?? "";
	}
}

/**
 * User profile initial state.
 */
const categoriesInitialState: ICategorySlice = {
	editing: false,
	editingList: [],
	list: [],
	loading: false,
	queried: false
	// error: ""
};

export { ICategory, Category, ICategorySlice, categoriesInitialState };
