/**
 * Contract for the AppBar component test automation.
 */
interface IAppBarTestIds {
	ContextMenu: string;
	Icon: string;
	Title: string;
	UserName: string;
	UserProfileButton: string;
	UserLogoutButton: string;
}

/**
 * Dictionary of test ids for the AppBar component test automation.
 */
const AppBarTestIds: IAppBarTestIds = {
	ContextMenu: "app-bar-context-menu",
	Icon: "app-bar-icon",
	Title: "app-bar-title",
	UserName: "app-bar-username",
	UserProfileButton: "app-bar-user-profile-button",
	UserLogoutButton: "app-bar-user-logout-button"
};

export { AppBarTestIds, IAppBarTestIds };
