import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";

import { IOption } from "components/shared/dropdown";
import { IBrand } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Brand.
 */
export const BrandSelectors = {
	getBrands: (state: RootState) => state.brands.list,
	getBrandsDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.brands.list?.map((brand: IBrand) => ({
				text: brand.name,
				key: brand.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getBrandById: (state: RootState, id: string) =>
		state.brands.list.find((brand: IBrand) => brand.id === id),
	getBrandsLoading: (state: RootState) => state.brands.loading,
	getBrandsQueried: (state: RootState) => state.brands.queried,
	getIsEditing: (state: RootState) => state.brands.editing,
	getBrandsEditing: (state: RootState) => state.brands.editingList
	// getBrandsError: (state: RootState) => state.brands.error
};
