import { ICategory } from "models/index";
import { actions } from "redux/reducers/categories/reducer";
import { ReduxStoreService } from "services/redux";
// import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";

/**
 * Class for managing the basics of the Category data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CategoryService extends ReduxStoreService<ICategory> {
	constructor() {
		super("categories", actions.setList, actions.setQueried);
	}
}

export { CategoryService };
