import { IOption } from "components/shared/dropdown/Dropdown";
import { ILandingMail } from "models/index";
import { RootState } from "redux/store";
import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";

/**
 * Selectors: LandingPage.
 */
export const LandingPageSelectors = {
	getMails: (state: RootState) => state.landingPage.list,
	getMailsDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.landingPage.list?.map((mailItem: ILandingMail) => ({
				text: mailItem.email,
				key: mailItem.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getLandingMailById: (state: RootState, id: string) =>
		state.landingPage.list.find((mail: ILandingMail) => mail.id === id),
	getMailsLoading: (state: RootState) => state.landingPage.loading,
	getMailsQueried: (state: RootState) => state.landingPage.queried,
	getIsEditing: (state: RootState) => state.landingPage.editing,
	getMailsEditing: (state: RootState) => state.landingPage.editingList
	// getProductsError: (state: RootState) => state.products.error
};
