import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";

import { IOption } from "components/shared/dropdown";
import { IClientOrder } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: ClientOrder.
 */
export const ClientOrderSelectors = {
	getClientOrders: (state: RootState) => state.clientOrders.list,
	getClientOrdersDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.clientOrders.list?.map((clientOrder: IClientOrder) => ({
				text: clientOrder.name,
				key: clientOrder.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getClientOrderById: (state: RootState, id: string) =>
		state.clientOrders.list.find(
			(clientOrder: IClientOrder) => clientOrder.id === id
		),
	getClientOrdersLoading: (state: RootState) => state.clientOrders.loading,
	getClientOrdersQueried: (state: RootState) => state.clientOrders.queried,
	getIsEditing: (state: RootState) => state.clientOrders.editing,
	getClientOrdersEditing: (state: RootState) => state.clientOrders.editingList
	// getClientOrdersError: (state: RootState) => state.clientOrders.error
};
