import { IClient } from "models/index";
import { actions } from "redux/reducers/clients/reducer";
import { CompanySegment } from "services/companies/CompanySegment";
import { whereLike } from "services/firebase/FirebaseServiceHandler";

const _baseStoragePath = (productId: string) => `clients/${productId}`;

/**
 * Class for managing the basics of the user's Company Client data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyClientService extends CompanySegment<IClient> {
	constructor(companyId: string) {
		super(companyId, "clients", actions.setList, actions.setQueried);
	}

	async searchClients(
		text: string,
		props: string[],
		onResolve: undefined | ((items: IClient[]) => void)
	): Promise<IClient[]> {
		const clients = Promise.all(
			props.map(async (propKey) => {
				const items = await this.queryItems(whereLike(propKey, text));
				return items;
			})
		).then((items) => {
			const _items = this.unifyLists(items);

			if (onResolve) {
				onResolve(_items);
			}

			return _items;
		});

		return clients;
	}

	async searchClientsByNameMail(
		text: string,
		onResolve: undefined | ((items) => void) = (items: IClient[]) => items
	): Promise<IClient[]> {
		return await this.searchClients(text, ["name", "mail"], onResolve);
	}

	// /**
	//  * Gets the latest SKU for a given category ID.
	//  *
	//  * @param categoryId The category from which to get the SKU initials
	//  * @param onComplete The callback to be processed when the SKU is generated
	//  * @returns
	//  */
	// async getByMail(clientMail: string): Promise<string> {
	// 	// const client = await this.getByField("mail", clientMail);

	// 	return client?.id ?? "";
	// }
}

export { CompanyClientService };
