import { appStateService } from "App";
import { IFirebaseItem } from "interfaces/index";
import {
	QueryFieldFilterConstraint,
	WhereFilterOp,
	query,
	where,
	addDoc,
	collection,
	doc,
	getDocs,
	setDoc,
	onSnapshot,
	Timestamp
} from "firebase/firestore";
import "firebase/firestore";
import { Unsubscribe } from "firebase/auth";
import { FirebaseError } from "firebase/app";

/**
 * Contract for the Firebase operators for querying.
 */
type PropQueryCriteria = [
	string,
	WhereFilterOp,
	string | number | boolean | Date | Timestamp | string[]
];

/**
 * Contract for the Firebase operators for querying.
 */
export interface IFirebaseOperators {
	equals: WhereFilterOp; //"==",
	lessThan: WhereFilterOp; //"<",
	lessThanOrEqual: WhereFilterOp; //"<=",
	greaterThan: WhereFilterOp; //">",
	greaterThanOrEqual: WhereFilterOp; //">=",
	arrayContains: WhereFilterOp; //"array-contains",
	arrayContainsAny: WhereFilterOp; //"array-contains-any",
	in: WhereFilterOp; //"in",
	notIn: WhereFilterOp; //"not-in"
	// [key: string]: WhereFilterOp;
}

/**
 * Firebase operators for querying.
 * From https://firebase.google.com/docs/firestore/query-data/queries#query_operators.
 */
export const FirebaseOperators: IFirebaseOperators = {
	equals: "==",
	lessThan: "<",
	lessThanOrEqual: "<=",
	greaterThan: ">",
	greaterThanOrEqual: ">=",
	arrayContains: "array-contains",
	arrayContainsAny: "array-contains-any",
	in: "in",
	notIn: "not-in"
};

/**
 * Returns the necessary criteria for a "where-like" query.
 * In Firestore, some indexing issues might occur - please, monitor the Console Log.
 *
 * @param propName The property name to use for filtering
 * @param value The value to use for filtering (creating the like expression)
 *
 * @returns The criteria for the Where-Like query.
 */
export function whereLike(
	propName: string,
	value: string,
	includeDeleted: boolean = false
): PropQueryCriteria[] {
	const conditions: PropQueryCriteria[] = [
		[propName, FirebaseOperators.greaterThanOrEqual, value],
		[propName, FirebaseOperators.lessThan, value + "\uf8ff"]
	];

	return includeDeleted
		? conditions
		: [["deleted", FirebaseOperators.equals, false], ...conditions];
}

/**
 * Class for managing the basics of any Firebase Firestore collection's data.
 */
class FirebaseServiceHandler<T extends IFirebaseItem> {
	private _deletedPropName: string = "deleted";
	private _collectionName: string;

	constructor(collectionName: string) {
		this._collectionName = collectionName;
	}

	/**
	 * Handles the error from Firebase commands.
	 *
	 * @param error The error to handle
	 *
	 * @returns The error message itself, after dealing with it.
	 */
	handleError(error: string | FirebaseError): string | FirebaseError {
		const errorMessage = "Firebase Error: " + error;
		if (process.env.NODE_ENV === "development") {
			console.log(errorMessage);
		}

		appStateService.error.handleError(
			new Error(JSON.stringify(error)),
			errorMessage
		);

		// throw Error(errorMessage);

		return error;
	}

	/**
	 * Returns all the items in the collection,
	 * Not including the deleted ones.
	 *
	 * @returns A promise with the items.
	 */
	async getItems(): Promise<T[]> {
		const items: T[] = [];
		const baseQuery = this.getBaseFilterQuery();

		const querySnapshot = await getDocs(
			query(
				collection(appStateService.firestore, this._collectionName),
				baseQuery
			)
		).catch((error) => {
			this.handleError(error);
		});

		if (!querySnapshot || !querySnapshot.docs) return items;

		querySnapshot.docs.forEach((doc) => {
			if (doc.exists()) {
				const identifiedData: T = doc.data() as T;

				// Rectify the use of ID, by the Firebase ID itself
				identifiedData.id =
					!identifiedData.id || identifiedData.id === ""
						? doc.id
						: identifiedData.id;

				items.push(identifiedData);
			}
		});

		return items;
	}

	/**
	 * Returns all the items in the collection,
	 * OnSnapshot of the collection (on DB changed).
	 * Not including the deleted ones.
	 *
	 * @returns A promise with the items.
	 */
	getItemsLive(onReady: (items: T[]) => void): Unsubscribe {
		const baseQuery = this.getBaseFilterQuery();

		const querySnapshot = onSnapshot(
			query(
				collection(appStateService.firestore, this._collectionName),
				baseQuery
			),
			(result) => {
				const items: T[] = [];

				if (!result || !result.docs) return items;

				result.docs.forEach((doc) => {
					if (doc.exists()) {
						const identifiedData: T = doc.data() as T;

						// Rectify the use of ID, by the Firebase ID itself
						if (!identifiedData.id || identifiedData.id === "") {
							identifiedData.id = doc.id;
						}

						items.push(identifiedData);
					}
				});

				onReady(items);
			},
			(error) => {
				this.handleError(error);
			}
		);

		return querySnapshot;
	}

	/**
	 * Returns the items that are not marked as deleted.
	 *
	 * @param prop The prop name to use for filtering
	 * @param value The value to use for filtering
	 * @param operator [Optional] The operator to use for filtering
	 *
	 * @returns A promise with the items that match the filter.
	 */
	async queryItemsByProp(
		prop: string,
		value: string,
		operator: WhereFilterOp = FirebaseOperators.equals
	): Promise<T[]> {
		let results: T[] = [];

		const queryInstruction = query(
			collection(appStateService.firestore, this._collectionName),
			where(prop, operator, value),
			this.getBaseFilterQuery()
		);

		const querySnapshot = await getDocs(queryInstruction).catch((error) => {
			this.handleError(error);
		});

		if (!querySnapshot || !querySnapshot.docs) return results;

		querySnapshot.docs.forEach((doc) => {
			if (doc.exists() && String(doc.data()[prop]).includes(value)) {
				results.push(doc.data() as T);
			}
		});

		return results;
	}

	/**
	 * Returns the items that are not marked as deleted.
	 *
	 * @param queryParams The query params to use for filtering
	 */
	async queryItems(queryParams: PropQueryCriteria[]): Promise<T[]> {
		let results: T[] = [];

		const whereClauses =
			queryParams.length > 0
				? queryParams.map((param) => {
						return where(param[0], param[1], param[2]);
				  })
				: [];

		const queryInstruction = query(
			collection(appStateService.firestore, this._collectionName),
			this.getBaseFilterQuery(),
			...whereClauses
		);

		const querySnapshot = await getDocs(queryInstruction).catch((error) => {
			this.handleError(error);
		});

		if (!querySnapshot || !querySnapshot.docs) return results;

		querySnapshot.docs.forEach((doc) => {
			if (doc.exists()) {
				results.push(doc.data() as T);
			}
		});

		return results;
	}

	/**
	 * Returns the item with the given id.
	 *
	 * @param id The id of the item to return
	 * @returns A promise with the item or null if not found.
	 */
	async getItemById(id: string): Promise<T | null> {
		const items = await this.queryItemsByProp("id", id);
		return items.find((item) => item.id === id) || null;
	}

	/**
	 * Creates a new item in the DB, in the collection specified in this instance.
	 *
	 * @param item The item to create
	 * @returns A promise with the result of the operation as boolean.
	 */
	async createItem(
		item: T,
		onCreated: (item: T) => void = undefined
	): Promise<boolean> {
		try {
			const userId: string = this.getUserId();

			const newDoc = await addDoc(
				collection(appStateService.firestore, this._collectionName),
				item
			);

			// Sets the item to with the backfill generated ID,
			// await this.updateItem({ id: result.id });
			await setDoc(
				doc(appStateService.firestore, this._collectionName, newDoc.id),
				{ id: newDoc.id, lastChangedBy: userId },
				{ merge: true }
			);

			// Copies the ID generated back to the instance
			item.id = newDoc.id;

			// If any callback was specified for after creation, calls it
			if (typeof onCreated === "function") onCreated(item);

			return true;
		} catch (ex) {
			// Calls the AppManagerService from the StateService in order to demonstrate an error message
			appStateService.error.handleError(ex);

			return false;
		}
	}

	/**
	 * Updates an item
	 *
	 * @param item
	 * @returns
	 */
	async updateItem(
		item: T,
		onUpdated: (item: T) => void = undefined
	): Promise<boolean> {
		try {
			const userId: string = this.getUserId();

			if (!item)
				throw Error("Cannot update an invalid object reference.");

			// const itemToUpdate = await this.getItemById(item.id);
			// if (!itemToUpdate) return this.createItem(item);

			if (!item.id) {
				throw Error("Cannot update an object without an ID.");
			}

			await setDoc(
				doc(appStateService.firestore, this._collectionName, item.id),
				{
					...item,
					lastChangedOn: Timestamp.now(),
					lastChangedBy: userId
				},
				{ merge: true }
			);

			// If any callback was specified for after update, calls it
			if (typeof onUpdated === "function") onUpdated(item);

			return !!item;
		} catch (ex) {
			// Calls the AppManagerService from the StateService in order to demonstrate an error message
			appStateService.error.handleError(ex);

			return false;
		}
	}

	/**
	 * Deletes an item from the DB, based on the ID.
	 *
	 * @param id The ID of the removing item.
	 *
	 * @returns
	 */
	async deleteItem(
		id: string,
		onDeleted: (itemId: string) => void = undefined
	): Promise<boolean> {
		try {
			const userId: string = this.getUserId();
			const itemToDelete = await this.getItemById(id);
			if (!itemToDelete) return false;

			await setDoc(
				doc(appStateService.firestore, this._collectionName, id),
				{
					deleted: true,
					deletedOn: Timestamp.now(),
					lastChangedBy: userId
				},
				{ merge: true }
			);

			// If any callback was specified for after deletion, calls it
			if (typeof onDeleted === "function") onDeleted(id);

			return true;
		} catch (ex) {
			// Calls the AppManagerService from the StateService in order to demonstrate an error message
			appStateService.error.handleError(ex);

			return false;
		}
	}

	getUserId(): string {
		const userId: string =
			appStateService.auth.getFromLocalState()?.profile.id ?? "";

		if (!userId || userId === "") {
			throw Error(
				"Cannot create an object without an identified user credential."
			);
		}

		return userId;
	}

	/**
	 * Returns the items that are not marked as deleted.
	 *
	 * @param items
	 * @returns
	 */
	private getBaseFilterQuery(): QueryFieldFilterConstraint {
		return where(this._deletedPropName, FirebaseOperators.equals, false);
	}

	/**
	 * Converts a JSON string into an object of T.
	 *
	 * @param data The string data to convert
	 * @returns The JSON object as T.
	 */
	fromJSONString(data: string): T {
		if (!data) {
			return null;
		}

		return JSON.parse(data) as T;
	}

	/**
	 * Converts a collection of collections into a single list of
	 * objects of type T. It includes a dupe checker.
	 *
	 * @param lists The collection of lists to unify
	 * @returns A unified version of the lists provided
	 */
	unifyLists<T extends IFirebaseItem>(lists: T[][]): T[] {
		const _items = lists.reduce((acc: T[], curr: T[]) => {
			const _acc = [...acc];
			curr.forEach((product) => {
				if (!_acc.find((p) => p.id === product.id)) {
					_acc.push(product);
				}
			});
			return _acc;
		});

		return _items;
	}
}

export { FirebaseServiceHandler };
