import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISetEditingAction } from "interfaces/actions";
import { aiModelProcessesInitialState, IAIModelProcess } from "models/index";
import { ReduxSlices } from "redux/slices";

/**
 * AIModelProcess Redux slice.
 */
const aiModelProcessSlice = createSlice({
	name: ReduxSlices.AIModelProcesses,
	initialState: aiModelProcessesInitialState,
	reducers: {
		setList: (state, action: PayloadAction<IAIModelProcess[]>) => {
			state.list = action.payload;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
			state.loading = false;
		},
		setEditing: (
			state,
			action: PayloadAction<ISetEditingAction<IAIModelProcess>>
		) => {
			state.editing = action.payload.editing;
			state.editingList = action.payload.items;
		},
		setEditByID: (state, action: PayloadAction<IAIModelProcess>) => {
			const index = state.list.findIndex(
				(item) => item.id === action.payload.id
			);
			if (index >= 0) {
				const newList = [...state.list];

				newList[index] = action.payload;

				state.list = newList;
			}
		},
		setEditingList: (state, action: PayloadAction<IAIModelProcess[]>) => {
			state.editingList = action.payload;
		},
		resetEditing: (state) => {
			state.editing = false;
			state.editingList = [];
		}
	}
});

export const {
	setList,
	setLoading,
	setQueried,
	setEditing,
	setEditByID,
	setEditingList,
	resetEditing
} = aiModelProcessSlice.actions;

export const { reducer, actions } = aiModelProcessSlice;

export default reducer;
