// listSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { listInitialState } from "models/base/List";

const listSlice = createSlice({
	name: "list",
	initialState: listInitialState,
	reducers: {
		selectItem: (state, action) => {
			const itemId = action.payload;
			// Add the itemId to the selected array if it's not already selected
			if (!state.selected.includes(itemId)) {
				state.selected.push(itemId);
			}
		},
		deselectItem: (state, action) => {
			const itemId = action.payload;
			// Remove the itemId from the selected array
			state.selected = state.selected.filter((id) => id !== itemId);
		},
		selectAllItems: (state, action) => {
			// Select all items
			state.selected = action.payload;
		},
		clearSelectedItems: (state) => {
			// Clear the selected items array
			state.selected = [];
		},
		setSelected: (state, action) => {
			state.selected = action.payload;
		},
		setFilter: (state, action) => {
			state.filter = action.payload;
		},
		setFilterProp: (state, action) => {
			state.filterProp = action.payload;
		},
		setSortProp: (state, action) => {
			state.sortProp = action.payload;
		},
		setSortDirection: (state, action) => {
			state.sortDirection = action.payload;
		},
		setEditing: (state, action) => {
			state.editing = action.payload;
		}
	}
});

export const {
	selectItem,
	deselectItem,
	selectAllItems,
	clearSelectedItems,
	setSelected,
	setFilter,
	setFilterProp,
	setSortProp,
	setSortDirection,
	setEditing
} = listSlice.actions;

export default listSlice.reducer;
