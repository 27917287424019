import { createSelector } from "@reduxjs/toolkit";
import { IProduct } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Product.
 */
export const ProductSelectors = {
	getProducts: (state: RootState) => state.products.list,
	getProductByIds: createSelector(
		(state: RootState, itemIds: string[]) => itemIds,
		(state: RootState) => state.products.list,
		(itemIds, products: IProduct[]) =>
			products?.filter((product) => itemIds.includes(product.id)) ?? []
	),
	getProductById: createSelector(
		(state: RootState, itemId: string) => itemId,
		(state: RootState) => state.products.list,
		(itemId, products: IProduct[]) =>
			products.find((product) => product.id === itemId)
	),
	getProductsLoading: (state: RootState) => state.products.loading,
	getProductsQueried: (state: RootState) => state.products.queried,
	getIsEditing: (state: RootState) => state.products.editing,
	getProductsEditing: (state: RootState) => state.products.editingList
	// getProductsError: (state: RootState) => state.products.error
};
