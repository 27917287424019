import React, { useMemo } from "react";

import "./Loader.scss";

/**
 * Contract for the Properties of the Loader component
 */
interface ILoaderProps {
	open: boolean;
	text?: string;
	className?: string;
	inline?: boolean;
}

/**
 * Re-usable Loader component
 *
 * @returns JSX.Element
 */
function Loader(props: ILoaderProps): JSX.Element {
	const loaderText = useMemo(() => props?.text ?? "", [props.text]);
	const loaderSpinnerClass = useMemo(
		() => (props?.inline ? "loader__spinner inline" : "loader__spinner"),
		[props.inline]
	);
	const loaderStyle = useMemo(
		() => (props?.inline ? "loader-inline" : "loader"),
		[props.inline]
	);
	const className = useMemo(
		() =>
			props.className ? `${loaderStyle} ${props.className}` : loaderStyle,
		[props.className, loaderStyle]
	);

	return props.open ? (
		<div className={className}>
			<div className={loaderSpinnerClass}></div>
			{loaderText ? (
				<div className="loader__text">{loaderText}</div>
			) : null}
		</div>
	) : null;
}

export { Loader, ILoaderProps };
