import { AIModelProcessState } from "enums";
import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";

/**
 * Contract for Usage data for the AI Model Process.
 */
interface IAIModelProcessUsage {
	prompt_tokens: number;
	completion_tokens: number;
	total_tokens: number;
}

/**
 * AIModelProcess basic interface.
 */
interface IAIModelProcess extends IFirebaseItem {
	id: string;
	modelId: string;
	openAIModelId: string;
	owner: string;
	state: AIModelProcessState;
	input: string;
	output: string;
	error: string;
	processingStarted: string | Timestamp;
	processingCompleted: string | Timestamp;
	usage: IAIModelProcessUsage;
}

/**
 * AIModelProcess Redux slice interface.
 */
interface IAIModelProcessSlice extends ISliceList<IAIModelProcess> {}

/**
 * AIModelProcess factory class.
 */
class AIModelProcess implements IAIModelProcess {
	id = new Date().getTime().toString();
	modelId = "";
	openAIModelId = "";
	owner = "";
	state = AIModelProcessState.New;
	input = "";
	output = "";
	error = "";
	processingStarted = null;
	processingCompleted = null;
	usage: IAIModelProcessUsage;
	timestamp = Timestamp.now();
	deleted: boolean = false;

	constructor(data?: undefined | IAIModelProcess) {
		this.id = data?.id ?? this.id;
		this.modelId = data?.modelId ?? this.modelId;
		this.openAIModelId = data?.openAIModelId ?? this.openAIModelId;
		this.owner = data?.owner ?? this.owner;
		this.state = data?.state ?? this.state;
		this.input = data?.input ?? this.input;
		this.output = data?.output ?? this.output;
		this.error = data?.error ?? this.error;
		this.processingStarted =
			data?.processingStarted ?? this.processingStarted;
		this.processingCompleted =
			data?.processingCompleted ?? this.processingCompleted;
		this.usage = data?.usage ?? this.usage;
		this.timestamp = data?.timestamp ?? this.timestamp;
		this.deleted = data?.deleted ?? this.deleted;
	}
}

/**
 * AI Model Process list initial state.
 */
const aiModelProcessesInitialState: IAIModelProcessSlice = {
	list: [],
	editingList: [],
	queried: false,
	loading: false,
	editing: false
};

export {
	IAIModelProcess,
	AIModelProcess,
	IAIModelProcessUsage,
	IAIModelProcessSlice,
	aiModelProcessesInitialState
};
