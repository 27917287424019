import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlices } from "redux/slices";
import { appConfigInitialState as initialState, IAppConfig } from "models";
import { MessageModalType } from "enums";

/**
 * Categories Redux slice.
 */
export const categoriesSlice = createSlice({
	name: ReduxSlices.AppConfig,
	initialState,
	reducers: {
		/**
		 * Sets the whole app configuration base on its domain model.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setConfig: (state, action: PayloadAction<IAppConfig>): void => {
			state = action.payload;
		},
		/**
		 * Sets the app bar title.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setAppBarHidden: (state, action: PayloadAction<boolean>): void => {
			state.appBar.hidden = action.payload;
		},
		/**
		 * Sets the app bar title.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setAppBarTitle: (state, action: PayloadAction<string>): void => {
			state.appBar.title = action.payload;
		},
		/**
		 * Cleans up and resets the App Config state.
		 *
		 * @param state The current state of the app.
		 */
		reset: (state) => {
			state = initialState;
		},
		/**
		 * Sets the loading state of the App Config.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setLoading: (state, action: PayloadAction<boolean>): void => {
			state.loader.loading = action.payload;
		},
		/**
		 * Sets the loading text of the App Config.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setLoadingText: (state, action: PayloadAction<string>): void => {
			state.loader.loadingText = action.payload;
		},
		/**
		 * Sets the message modal type.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setMessageModalType: (
			state,
			action: PayloadAction<MessageModalType>
		): void => {
			state.messageModal.type = action.payload;
		},
		/**
		 * Sets the Message Modal open state of the App.
		 *
		 * @param state The current state of the app
		 * @param action The action with current open state
		 */
		setMessageModalOpen: (state, action: PayloadAction<boolean>): void => {
			state.messageModal.open = action.payload;
		},
		/**
		 * Sets the Message Modal title of the App.
		 *
		 * @param state The current state of the app
		 * @param action The action with current title
		 */
		setMessageModalTitle: (state, action: PayloadAction<string>): void => {
			state.messageModal.title = action.payload;
		},
		/**
		 * Sets the Message Modal text of the App.
		 *
		 * @param state The current state of the app
		 * @param action The action with current text
		 */
		setMessageModalText: (state, action: PayloadAction<string>): void => {
			state.messageModal.text = action.payload;
		}
	}
});

export const { reducer, actions } = categoriesSlice;

export default reducer;

// TODO: Selectors, split file
