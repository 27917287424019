import { IOption } from "../dropdown";

/**
 * Handles the Change event for a Text or TextArea component.
 *
 * @param fieldName The name of the field to be updated.
 * @param setNewItem The function to update the item.
 * @returns The function to handle the Change event.
 */
export function onChangeField<TItem>(
	fieldName: string,
	setNewItem: (prev) => TItem
) {
	return (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		// When the input is a number type, the value should be parsed back to floating point.
		const value: number | string | TItem =
			event?.target?.type === "number"
				? parseFloat(event?.target?.value)
				: event?.target?.value;

		setNewItem((prev: TItem) => ({ ...prev, [fieldName]: value }));
	};
}

/**
 * Handles the Change event for a Select component.
 *
 * @param fieldName The name of the field to be updated.
 * @param setNewItem The function to update the item.
 *
 * @returns The function to handle the Change event.
 */
export function onChangeSelect<TItem, IOptionType extends IOption>(
	fieldName: string,
	setNewItem: (prev) => TItem
): (event: any, option: IOptionType) => void {
	return (event: any, option: IOptionType) => {
		const value = option.key;
		setNewItem((prev) => ({ ...prev, [fieldName]: value }));
	};
}
