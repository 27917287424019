import { ISupplier } from "models/index";
import { actions } from "redux/reducers/suppliers/reducer";
import { CompanySegment } from "services/companies/CompanySegment";

/**
 * Class for managing the basics of the Company Supplier data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanySupplierService extends CompanySegment<ISupplier> {
	constructor(companyId: string) {
		super(companyId, "suppliers", actions.setList, actions.setQueried);
	}
}

export { CompanySupplierService };
