import { FirebaseFunctionsService } from "services/firebase/functions";

/**
 * Used to authenticate a first-login User Validation Code.
 */
class ValidateCustomerCodeService extends FirebaseFunctionsService {
	constructor() {
		super("validateUserCode");
	}
}

export { ValidateCustomerCodeService };
