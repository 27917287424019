import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IClient, IProduct } from "models/index";
import { IClientOrder, IOrderItem, orderFormInitialState } from "models/order";
import { ReduxSlices } from "redux/slices";

/**
 * Order Form Redux slice.
 */
export const orderFormSlice = createSlice({
	name: ReduxSlices.ClientOrderForm,
	initialState: orderFormInitialState,
	reducers: {
		/**
		 * Sets the whole order form base on its domain model.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setOrderForm: (state, action: PayloadAction<IClientOrder>) => {
			state.order = action.payload;
		},
		/**
		 * Cleans up and resets the Order Form state.
		 *
		 * @param state The current state of the app.
		 */
		reset: (state) => {
			state.order = orderFormInitialState.order;
			state.productsList = orderFormInitialState.productsList;
			state.loading = orderFormInitialState.loading;
			state.queried = orderFormInitialState.queried;
			state.addingProduct = orderFormInitialState.addingProduct;
			state.productSearchText = orderFormInitialState.productSearchText;
		},
		/**
		 * Sets the loading state of the Order Form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		/**
		 * Sets the queried state of the Order Form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setQueried: (state, action: PayloadAction<boolean>) => {
			state.queried = action.payload;
		},
		/**
		 * Sets the products list of the Order Form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setProductsList: (state, action: PayloadAction<IProduct[]>) => {
			state.productsList = action.payload;
		},
		/**
		 * Adds a product to the order form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		addProduct: (state, action: PayloadAction<IOrderItem>) => {
			state.order.items.push(action.payload);
		},
		/**
		 * Removes a product from the order form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		removeProduct: (state, action: PayloadAction<IOrderItem>) => {
			const index = state.order.items.findIndex(
				(item) => item.itemId === action.payload.itemId
			);
			state.order.items.splice(index, 1);
		},
		/**
		 * Sets the adding product state of the Order Form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setAddingProduct: (state, action: PayloadAction<boolean>) => {
			state.addingProduct = action.payload;
		},
		/**
		 * Sets the product search text of the Order Form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		setProductSearchText: (state, action: PayloadAction<string>) => {
			state.productSearchText = action.payload;
		},
		/**
		 * Adds a Client to the Order Form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		addClientToOrderForm: (state, action: PayloadAction<IClient>) => {
			state.order.clientId = action.payload.id;
			state.order.name = action.payload.name;
			state.order.email = action.payload.mail;
			state.order.phone = action.payload.phone;
			// TODO: Edit this logic
			// state.order.addingNewClient = !action.payload.id;
		},
		/**
		 * Removes an Order Item from the Order Form.
		 *
		 * @param state The current state of the app
		 * @param action The action with data to be carried
		 */
		removeOrderItem: (state, action: PayloadAction<string>) => {
			const index = state.order.items.findIndex(
				(item) => item.itemId === action.payload
			);
			state.order.items.splice(index, 1);
		},
		/**
		 * Resets the Order Form state.
		 *
		 * @param state The current app state
		 */
		resetOrder: (state) => {
			state.order = { ...orderFormInitialState.order };
		}
	}
});

export const { reducer, actions } = orderFormSlice;

export default reducer;
