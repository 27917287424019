import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setNavigation } from "components/router/NavigateHelper";

/**
 * Hook for configuring the navigate function,
 * usable from anywhere in the app.
 *
 * @returns
 */
export const useNavigatorReference = () => {
	const navigate = useNavigate();

	useEffect(() => {
		// Set the navigation function reference when the component mounts
		setNavigation(navigate);
	}, [navigate]);

	return navigate;
};
