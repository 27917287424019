import { IStock } from "models/index";
import { actions } from "redux/reducers/stock/list/reducer";
import { ReduxStoreService } from "services/redux";
// import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";

/**
 * Class for managing the basics of the Root Stock data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class StockService extends ReduxStoreService<IStock> {
	constructor() {
		super("productStock", actions.setList, actions.setQueried);
	}

	/**
	 * Gets the list of stocks by product ID.
	 *
	 * @param productId The product ID referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getByProductId(productId: string): Promise<IStock[]> {
		return this.queryItemsByProp("productId", productId);
	}

	/**
	 * Gets the list of stocks by supplier ID.
	 *
	 * @param supplierId The supplier ID referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getBySupplierId(supplierId: string): Promise<IStock[]> {
		return this.queryItemsByProp("supplierId", supplierId);
	}

	/**
	 * Gets the list of stocks by product SKU.
	 *
	 * @param productSku The product SKU referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getByProductSku(productSku: string): Promise<IStock[]> {
		return this.queryItemsByProp("productSku", productSku);
	}
}

export { StockService };
