import { IFirebaseItem } from "interfaces/index";
import { ReduxStoreService } from "services/redux";

const _baseStoragePath = (companyId: string) => `companies/${companyId}`;

// TODO: Pull company ID from current User active company

/**
 * Class for managing the basics of the Product data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanySegment<T extends IFirebaseItem> extends ReduxStoreService<T> {
	constructor(
		companyId: string,
		subContext: string = "",
		setList: any,
		setQueried: any
	) {
		super(
			!subContext
				? _baseStoragePath(companyId)
				: _baseStoragePath(`${companyId}/${subContext}`),
			setList,
			setQueried
		);
	}
}

export { CompanySegment };
