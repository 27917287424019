import { appStateService } from "App";
import { ILandingMail, LandingMail } from "models/index";
import { actions } from "redux/reducers/landingPage/reducer";
import { ReduxStoreService } from "services/redux";
// import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";

/**
 * Class for managing the basics of the Product data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class LandingMailService extends ReduxStoreService<ILandingMail> {
	constructor() {
		super("landingMails", actions.setList, actions.setQueried);
	}

	/**
	 * Creates a new landing mail, for external users, by only informing the mail as string.
	 *
	 * @param mail The mail to be created.
	 * @returns 200 if successful.
	 */
	async createExternal(mail: string): Promise<boolean> {
		const item = new LandingMail();
		item.email = mail;

		const response = this.createItem(item, () => {
			appStateService.appManager.showSuccess(
				"E-mail cadastrado com sucesso."
			);
		});

		return response;
	}
}

export { LandingMailService };
