import React from "react";
import { IconButton } from "@fluentui/react";
import { IIconButtonProps } from "interfaces/index";

const iconProps = {
	iconName: "ShoppingCartSolid"
};

/**
 * Re-usable Close icon button component
 *
 * @param param0
 * @returns
 */
export function ShoppingCartSolidButton({
	onClick,
	className,
	...otherProps
}: IIconButtonProps) {
	return (
		<IconButton
			iconProps={iconProps}
			onClick={onClick}
			className={`button icon ${className ?? ""}`}
			{...otherProps}
		/>
	);
}
