/**
 * Interface for the Login page component test automation.
 */
interface ILoginTestIds {
	Button: {
		Login: string;
		SignUp: string;
		forgotPassword: string;
		ShowHidePassword: string;
	};
	Drawer: string;
	ImageRights: string;
	Input: {
		Username: string;
		Password: string;
	};
	Label: {
		Username: string;
		Password: string;
	};
	LoginContainer: string;
	Logo: string;
	Title: string;
	VersionTag: string;
}

/**
 * Dictionary of test ids for the Login page component test automation.
 */
const LoginTestIds: ILoginTestIds = {
	Button: {
		Login: "page-login-button-login",
		SignUp: "page-login-button-signup",
		forgotPassword: "page-login-button-forgot-password",
		ShowHidePassword: "page-login-button-show-hide-password"
	},
	Drawer: "page-login-drawer",
	ImageRights: "page-login-image-rights",
	Input: {
		Username: "page-login-input-username",
		Password: "page-login-input-password"
	},
	Label: {
		Username: "page-login-label-username",
		Password: "page-login-label-password"
	},
	LoginContainer: "page-login-container",
	Logo: "page-login-logo",
	Title: "page-login-title",
	VersionTag: "page-login-version-tag"
};

export { LoginTestIds, ILoginTestIds };
