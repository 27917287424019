import { appStateService } from "App";
import { IStock } from "models/index";
import { actions } from "redux/reducers/stock/list/reducer";
import { CompanySegment } from "services/companies/CompanySegment";

/**
 * Class for managing the basics of the Company Product Stock data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyStockService extends CompanySegment<IStock> {
	constructor(companyId: string) {
		super(companyId, "productStock", actions.setList, actions.setQueried);
	}

	/**
	 * Gets the list of stocks by product ID.
	 *
	 * @param productId The product ID referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getByProductId(productId: string): Promise<IStock[]> {
		return this.queryItemsByProp("productId", productId);
	}

	/**
	 * Gets the list of stocks by supplier ID.
	 *
	 * @param supplierId The supplier ID referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getBySupplierId(supplierId: string): Promise<IStock[]> {
		return this.queryItemsByProp("supplierId", supplierId);
	}

	/**
	 * Gets the list of stocks by product SKU.
	 *
	 * @param productSku The product SKU referenced by the Stock entry.
	 * @returns A list of stocks.
	 */
	async getByProductSku(productSku: string): Promise<IStock[]> {
		return this.queryItemsByProp("productSku", productSku);
	}

	// TODO: Implement missing functionalities

	/**
	 * Registers a new stock in/out, to a given product ID.
	 *
	 * @param productIdOrSKU The product ID or SKU to query with.
	 * @param quantity The quantity to register moving in/out to stock.
	 * @param isOut A boolean indicating if the movement is out of stock.
	 * @returns A boolean indicating the success of the operation.
	 */
	async registerMovement(
		productIdOrSKU: string,
		quantity: number,
		isOut: boolean
	): Promise<boolean> {
		const productSvc = appStateService.company.product.get();

		if (!productIdOrSKU || quantity <= 0) return false;

		const productEntries = this.unifyLists([
			[await productSvc.getItemById(productIdOrSKU)],
			[await productSvc.getBySKU(productIdOrSKU)]
		]);

		const foundEntries = await this.getByProductId(productIdOrSKU);

		if (!productEntries || productEntries.length === 0) return false;

		if (foundEntries.length === 0) {
			// TODO: Study this case when no entry is found
			throw new Error("No entry found");
			// // Create a new entry
			// return this.createItem({
			// 	productId,
			// 	quantity,
			// 	productSku
			// });
		}

		// Update the existing entry
		const foundStock = foundEntries[0];

		// Based on the movement type, checks if the quantity is available
		if (isOut) {
			if (foundStock.quantity < quantity)
				throw new Error("not-enough-stock");
			foundStock.quantity -= quantity;
		} else {
			foundStock.quantity += quantity;
		}

		return await this.updateItem(foundStock);
	}

	/**
	 * Registers a new stock entry, to a given product ID.
	 *
	 * @param productIdOrSKU The product ID or SKU to query with.
	 * @param quantity The quantity to register entering to stock.
	 * @returns A boolean indicating the success of the operation.
	 */
	async registerEntering(
		productIdOrSKU: string,
		quantity: number
	): Promise<boolean> {
		return await this.registerMovement(productIdOrSKU, quantity, false);
	}

	/**
	 * Registers a new stock out, to a given product ID.
	 *
	 * @param productIdOrSKU The product ID or SKU to query with.
	 * @param quantity The quantity to register moving out of stock.
	 * @returns A boolean indicating the success of the operation.
	 */
	async registerOutgoing(
		productIdOrSKU: string,
		quantity: number
	): Promise<boolean> {
		return await this.registerMovement(productIdOrSKU, quantity, true);
	}
}

export { CompanyStockService };
