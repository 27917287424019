import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";

/**
 * User application roles list interface.
 */
interface IUserApplicationRoles {
	admin: boolean;
}

/**
 * User profile interface.
 */
interface IUserProfile extends IFirebaseItem {
	name: string;
	mail: string;
	pictureURL: string;
	displayName?: string;
	activeCompany: string;
	companies: string[];
	roles: IUserApplicationRoles;
}

/**
 * User profile form interface.
 */
interface IUserProfileForm {
	isOpen: boolean;
	isEditingInfo: boolean;
}

/**
 * User profile slice interface.
 */
interface IUserProfileSlice {
	profile: IUserProfile;
	authenticating: boolean;
	authenticated: boolean;
	form: IUserProfileForm;
}

// /**
//  * Base class for the state definition to be extractable from classes which extend it.
//  */
// export class InitialStateDefinition<T> {
// 	constructor() {}

// 	toInitialState(): T {
// 		return { ...this };
// 	}
// }

/**
 * User profile factory class.
 */
export class UserProfileSlice implements IUserProfileSlice {
	profile = {
		name: "",
		displayName: "",
		mail: "",
		id: "",
		pictureURL: "",
		timestamp: Timestamp.now(),
		deleted: false,
		activeCompany: "",
		companies: [],
		roles: {
			admin: false
		}
	};

	// Other props, e.g., Form state
	authenticating: boolean = false;
	authenticated: boolean = false;
	form = {
		isOpen: false,
		isEditingInfo: false
	};

	constructor(data?: undefined | IUserProfileSlice) {
		// User profile props
		this.profile.name = data?.profile.name ?? "";
		this.profile.displayName = data?.profile.displayName ?? "";
		this.profile.mail = data?.profile.mail ?? "";
		this.profile.id = data?.profile.id ?? "";
		this.profile.pictureURL = data?.profile.pictureURL ?? "";
		this.profile.timestamp = data?.profile.timestamp ?? Timestamp.now();
		this.profile.deleted = data?.profile.deleted ?? false;
		this.profile.activeCompany = data?.profile.activeCompany ?? "";
		this.profile.companies = data?.profile.companies ?? [];
		this.profile.roles = data?.profile.roles ?? { admin: false };

		// Other state props
		this.authenticating = data?.authenticating ?? false;
		this.authenticated = data?.authenticated ?? false;
		this.form = data?.form ?? {
			isOpen: false,
			isEditingInfo: false
		};
	}
}

// const userProfileInitialState: IUserProfileSlice = { ...new UserProfile() };
const userProfileInitialState: IUserProfileSlice = {
	profile: {
		name: "",
		displayName: "",
		mail: "",
		id: "",
		pictureURL: "",
		timestamp: Timestamp.now(),
		deleted: false,
		activeCompany: "",
		companies: [],
		roles: {
			admin: false
		}
	},
	authenticating: false,
	authenticated: false,
	form: { isOpen: false, isEditingInfo: false }
};

export {
	IUserProfile,
	IUserProfileSlice,
	IUserProfileForm,
	IUserApplicationRoles,
	userProfileInitialState
};
