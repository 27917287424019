import React from "react";
import { InputText } from "./InputText";

/**
 * InputGroup props contract interface.
 */
declare type InputGroupProps = {
	label: string;
	value: string;
	id: string;
	className: string;
	onChange?: undefined | ((event: any) => void);
	disabled?: undefined | boolean;
	name?: undefined | string;
	placeholder?: undefined | string;
	required?: undefined | boolean;
};

/**
 * InputGroup shared component.
 *
 * @param props
 * @returns
 */
const InputGroup: React.FC<InputGroupProps> = (props) => {
	const {
		label,
		value,
		id,
		className,
		onChange = () => {},
		disabled = false,
		name = id,
		placeholder = "",
		required = false
	} = props;

	return (
		<div>
			<label htmlFor={name}>
				{label}
				{required ? " *" : ""}
			</label>
			<InputText
				id={id}
				value={value}
				name={name}
				onChange={(event) => onChange(event)}
				className={className}
				placeholder={placeholder}
				required={required}
				disabled={disabled}
			/>
		</div>
	);
};

export { InputGroup, type InputGroupProps };
