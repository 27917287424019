import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";

/**
 * Brand Redux slice interface.
 */
interface BrandState {
	brands: Brand[];
	editingBrand: Brand | null;
}

/**
 * Brand basic interface.
 */
interface IBrand extends IFirebaseItem {
	name: string;
	country: string;
	description: string;
}

/**
 * Brand Redux slice interface.
 */
interface IBrandSlice extends ISliceList<IBrand> {}

/**
 * Brand factory class.
 */
class Brand implements IBrand {
	id: string = "";
	name: string = "";
	country: string = "";
	description: string = "";
	deleted: boolean = false;
	timestamp: Timestamp;
	lastChangedBy: string = "";

	constructor(data?: undefined | IBrand) {
		this.id = data?.id ?? "";
		this.name = data?.name ?? "";
		this.country = data?.country ?? "";
		this.description = data?.description ?? "";
		this.deleted = data?.deleted ?? false;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.lastChangedBy = data?.lastChangedBy ?? "";
	}
}

/**
 * Initial state for the brand slice.
 */
const brandInitialState: IBrandSlice = {
	list: [],
	queried: false,
	loading: false,
	editing: false,
	editingList: []
};

export { IBrandSlice, IBrand, Brand, BrandState, brandInitialState };
