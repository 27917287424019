import { appStateService } from "App";
import { ClientOrderStatus, SumUpPaymentPurpose } from "enums";
import { translate } from "hooks/i18n";
import { ClientOrder, IClientOrder } from "models/index";
import { actions } from "redux/reducers/orders/client/reducer";
import { CompanySegment } from "services/companies/CompanySegment";

/**
 * Class for managing the basics of the Company's Client Order data list.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyOrderService extends CompanySegment<IClientOrder> {
	constructor(companyId: string) {
		super(companyId, "clientOrders", actions.setList, actions.setQueried);
	}

	/**
	 * Overrides the base createItem, since a specific approach
	 * needs to take place for Checkout generation.
	 *
	 * @param item The item to be created.
	 * @param onCreated [Optional] Callback function to be executed after the item is created.
	 */
	async createItem(
		item: IClientOrder,
		onCreated?: (item: IClientOrder) => void
	): Promise<boolean> {
		let response = await super.createItem(item, onCreated);

		if (response) {
			response = await this.createCheckout(item.id);
		}

		return response;
	}

	async createCheckout(orderId: string): Promise<boolean> {
		let newCheckout;
		let currentOrder: IClientOrder;
		const orderDate = new Date();
		const orderValidUntil = orderDate;

		// Adds 2 days to the order expiration date
		orderValidUntil.setDate(orderDate.getDate() + 2);

		currentOrder = await this.getItemById(orderId);

		newCheckout = {
			amount: currentOrder.payment.value,
			currency: "BRL",
			checkout_reference: orderId,
			// customer_id: // TODO: get the customer ID from the order
			date: orderDate.toISOString(),
			description: `${appStateService.payment.sumup.getMerchantName()}: Pedido de Compra ID ${orderId}`,
			merchant_code: appStateService.payment.sumup.getMerchantCode(),
			pay_to_email: appStateService.payment.sumup.getMerchantMail(),
			payment_type: currentOrder.payment.paymentType,
			purpose: SumUpPaymentPurpose.SETUP_RECURRING_PAYMENT,
			redirect_url: "", // TODO: Set the Payment Notification URL to receive return from SumUp
			valid_until: orderValidUntil.toISOString()
		};

		const checkoutResponse = await appStateService.payment.sumup
			.createCheckout(newCheckout)
			.catch((error) => {
				return error;
			});

		// Somewhat the response is rather invalid at this stage
		if (!checkoutResponse) {
			await this.error(
				orderId,
				translate("page.order.internal.checkout.criticalError"),
				ClientOrderStatus.cancelled
			);

			return false;
		}

		if (
			checkoutResponse instanceof Error &&
			checkoutResponse.message &&
			checkoutResponse.stack
		) {
			// TODO: Log error information
			await this.error(orderId, checkoutResponse.message);

			appStateService.appManager.showError(checkoutResponse.message);

			return false;
		}

		debugger;

		this.updateItem({
			...currentOrder,
			payment: {
				...currentOrder.payment,
				checkoutId: checkoutResponse.id
			}
		});

		appStateService.appManager.showSuccess(
			translate("page.order.internal.checkout.success")
		);

		return true;
	}

	/**
	 * Updates the status of the order to "error",
	 * As well as maintains the error message.
	 *
	 * @param orderId
	 * @param errorMessage
	 */
	async error(
		orderId: string,
		errorMessage: string,
		status: ClientOrderStatus = ClientOrderStatus.error
	) {
		const currentOrder = await this.getItemById(orderId);

		this.updateItem({
			...new ClientOrder(currentOrder),
			status: ClientOrderStatus.error,
			errorMessage
		});
	}
}

export { CompanyOrderService };
