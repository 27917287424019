import React from "react";
import ReactDOM from "react-dom/client";

import reportWebVitals from "./reportWebVitals";
import { SentryServiceInstance } from "services/sentry/SentryService";
import { initializeIcons } from "@fluentui/react";

import App from "./App";

import "./index.scss";

// In case it's a dev environment, we can enable some extra features
if (process.env.NODE_ENV === "development") {
} else {
	// Disables the console logs for other environments
	console.log = (message) => {};
	console.warn = (message) => {};
	console.info = (message) => {};
}

// Initiates the Sentry service
const _sentryMonitor = SentryServiceInstance; //new SentryService();

if (process.env.NODE_ENV !== "production") {
	// Reports the Sentry service status
	console.log(
		`[Non-prod] O serviço Sentry foi iniciado às ${_sentryMonitor.startupTime.toLocaleString()}.`
	);
}

// Required by FluentUI to have the icons operative
initializeIcons();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
