import React, { useCallback, useState } from "react";
import { Button } from "components/shared/button";
import { Loader } from "components/shared/loader";
import { useDebouncedClick } from "hooks/useDebouncedClick";

import "./AIFunctionButton.scss";

/**
 * Contract for the properties of the AIButton component.
 */
declare type AIFunctionButtonProps = {
	text: string;
	onClick: () => Promise<void>;
	onCompleted?: undefined | (() => Promise<void>);
	disabled?: boolean;
	className?: string;
};

/**
 * Re-usable AIButton component.
 * Executes a single function, producing a single result.
 *
 * @param props
 * @returns
 */
const AIFunctionButton: React.FC<AIFunctionButtonProps> = (props) => {
	const titles = {
		actions: {
			generateDescription: "Usar IA (Bee AI)"
		}
	};
	const [isQuerying, setIsQuerying] = useState(false);
	// const isQuerying = useRef(false);

	/**
	 * Handles the completion of the AI function.
	 */
	const handleCompleteAIQuery = useCallback(() => {
		if (typeof props?.onCompleted === "function")
			Promise.resolve(props.onCompleted()).then(() => {
				setIsQuerying(false);
			});
		else setIsQuerying(false);
	}, [props]);

	/**
	 * Handles the starting trigger of the AI function, using the Bee AI.
	 */
	const handleStartAIQuery = useDebouncedClick(() => {
		setIsQuerying(true);

		Promise.resolve(props.onClick()).then(() => {
			setTimeout(() => {
				handleCompleteAIQuery();
			}, 200);
		});

		// handleCompleteAIQuery();
	}, 400);

	return (
		<div className="ai-function-group">
			<Button
				text={props?.text ?? titles.actions.generateDescription}
				onClick={handleStartAIQuery}
				disabled={props?.disabled ?? undefined}
				className={props?.className ?? undefined}
			/>
			{isQuerying ? <Loader open inline /> : null}
		</div>
	);
};

export { type AIFunctionButtonProps, AIFunctionButton };
