import { appStateService } from "App";

/**
 * Contract for the Error management service structure.
 */
interface IErrorService {
	handleError: (
		error: Error | { message: string },
		message?: undefined | string
	) => Promise<void>;
	handleCriticalError: (
		error: Error | { message: string },
		message?: undefined | string
	) => Promise<void>;
}

/**
 * Service for handling with system errors in general
 */
export class ErrorService implements IErrorService {
	async handleError(error: Error, message?: undefined | string) {
		if (process.env.NODE_ENV === "development") console.error(error);
		appStateService.appManager.showError(message ? message : error.message);
	}

	async handleCriticalError(error: Error, message?: undefined | string) {
		if (process.env.NODE_ENV === "development") console.error(error);
		appStateService.appManager.showError(message ? message : error.message);
	}
}

export const ErrorServiceInstance = new ErrorService();
