import { appStateService, store } from "App";
import { ICompany } from "models/index";
import { actions } from "redux/reducers/companies/reducer";
import { CompanySelectors } from "redux/selectors/companies/companiesSelectors";
import { ReduxStoreService } from "services/redux";

const _baseStoragePath = (companyId: string) => `companies/${companyId}`;

/**
 * Class for managing the basics of the Product data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyService extends ReduxStoreService<ICompany> {
	constructor() {
		super("companies", actions.setList, actions.setQueried);
	}

	/**
	 * Gets all companies from authenticated user,
	 * and store them in the Redux store.
	 *
	 * @returns {Promise<ICompany[]>} List of companies
	 */
	async getUserCompanies(): Promise<ICompany[]> {
		const userCompanyIds = appStateService.user.getCompanies();

		const companies = await Promise.all(
			userCompanyIds.map((companyId) => this.getItemById(companyId))
		);

		// Sets the list of companies in the Redux store
		store.dispatch(actions.setList(companies));

		return companies;
	}

	/**
	 * Checks whether the instance of the app has successfully loaded the user companies.
	 */
	ensureLoadedUserCompanies() {
		const loading = CompanySelectors.getCompaniesLoading(store.getState());
		const queried = CompanySelectors.getCompaniesQueried(store.getState());
		const items = CompanySelectors.getCompanies(store.getState());
		const companyIds = appStateService.user.getCompanies();

		// Ensures the companies of the user are keps sync
		if (!loading && !queried && items.length !== companyIds.length) {
			this.getUserCompanies();
		}
	}

	/**
	 * Gets a company object based on a given company ID.
	 * Only user companies will display correctly.
	 *
	 * @param companyId The company ID to search in the Redux store.
	 * @returns {ICompany} The company object.
	 */
	getCompanyById(companyId: string): ICompany {
		return CompanySelectors.getCompanyById(store.getState(), companyId);
	}
}

export { CompanyService };
