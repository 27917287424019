import { appStateService, store } from "App";
import { IProduct, Product } from "models/index";
import { actions } from "redux/reducers/products/reducer";
import { ProductSelectors } from "redux/selectors";
import { FirebaseOperators } from "services/firebase/FirebaseServiceHandler";
import { AIModels } from "services/openai";
import { ReduxStoreService } from "services/redux";

const _config = {
	SKUSequentialLength: 4
};

const _baseStoragePath = (productId: string) => `products/${productId}`;

/**
 * Class for managing the basics of the Product data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class ProductService extends ReduxStoreService<IProduct> {
	constructor() {
		super("products", actions.setList, actions.setQueried);
	}

	/**
	 * Formats a SKU based on the category's SKU initial and the sequence number.
	 *
	 * @param skuInitial The category's SKU initial
	 * @param skuSequenceNumber The sequence number
	 * @returns The formatted SKU
	 */
	static formatSKU(
		skuInitial: string,
		skuSequenceNumber: number | string
	): string {
		return `${skuInitial}${String(skuSequenceNumber).padStart(
			_config.SKUSequentialLength,
			"0"
		)}`;
	}

	/**
	 * Gets the latest SKU for a given category ID.
	 *
	 * @param categoryId The category from which to get the SKU initials
	 * @param onComplete The callback to be processed when the SKU is generated
	 * @returns
	 */
	async getLatestSKUByCategory(
		categoryId: string,
		onComplete?: (generatedSKU: string) => void
	): Promise<string> {
		let resolvedSKU = undefined;

		const categoryById = await appStateService.category
			.get()
			.getItemById(categoryId);
		const highestSKUItem = await this.queryItemsByProp(
			"category",
			categoryId,
			FirebaseOperators.equals
		).then((result) => {
			if (!result || result.length === 0) {
				return null;
			}

			// pre-filters the list for empty SKUs, or non-matching patterns
			const filteredList = result.filter(
				(item) =>
					!!item.sku && item.sku.includes(categoryById.skuInitial)
			);

			// Pre-filtered list is empty, returns null
			if (filteredList.length === 0) return null;

			// Single result found, returns it
			if (filteredList.length === 1) return result[0];

			// sorts the list to find the latest SKU
			const sortedList = filteredList.sort((a, b) => {
				if (a.sku > b.sku) return -1;
				if (a.sku < b.sku) return 1;
				return 0;
			});

			return sortedList[0];
		});

		// const snapshot = await query.get();

		if (!categoryById) {
			return "invalid-category-sku";
		}

		try {
			if (highestSKUItem === null) {
				const generatedSKU = ProductService.formatSKU(
					categoryById.skuInitial,
					`1`
				);
				return generatedSKU;
			}

			const skuSequenceNumber = parseInt(
				highestSKUItem.sku.split(categoryById.skuInitial)[1]
			);

			resolvedSKU = ProductService.formatSKU(
				categoryById.skuInitial,
				`${skuSequenceNumber + 1}`
			);

			if (typeof onComplete === "function") {
				onComplete(resolvedSKU);
			}

			return resolvedSKU;
		} catch (error) {
			// TODO: Administrar erro de geração de SKU.
			return "error-generating-sku";
		}
	}

	/**
	 * Uploads an image to Firebase Storage and updates the product's images list.
	 *
	 * @param productId The product ID
	 * @param image The image to be uploaded (File)
	 */
	async uploadImage(
		productId: string,
		image: File,
		onProductUpdated: (product: IProduct) => Promise<void> = null
		// image: File | Blob | string
	): Promise<void> {
		try {
			const fileType = image instanceof File ? image.type : "image/jpeg";
			// const filePath = image instanceof File ? image.name : "image.jpg";
			const storagePath = `${_baseStoragePath(
				productId
			)}/images/${Date.now()}/${image.name}`;

			// let imageBase64 = "";
			await appStateService.firebaseStorage
				.uploadFile(
					image,
					// filePath,
					storagePath,
					fileType
				)
				.then((imagePath) => {
					// Gets the product, updates the images list and updates it in the.
					this.getItemById(productId).then(async (product) => {
						if (!product) return;

						const productCopy = { ...product };

						// When there are images already inserted in the product
						if (!!product?.images && product.images.length > 0) {
							// Checks if the image in question is already inserted in the product
							productCopy.images = product.images.includes(
								imagePath
							)
								? product.images
								: [...(product.images ?? []), imagePath];
						} else {
							// Creating the collection from scratch
							productCopy.images = [imagePath];
						}

						await this.updateItem(productCopy, onProductUpdated);
					});
				});

			// const reader = new FileReader();
			// reader.addEventListener("load", async (event) => {
			// 	imageBase64 = btoa(event.target.result.toString());

			// });
			// reader.readAsDataURL(image);

			// TODO: Generate Download URL and Update the entry on Firebase Firestore
			// const downloadURL =
			// 	await getDownloadURL(
			// 		storageRef
			// 	);

			// await this.update({
			// 	id: productId,
			// 	images: [
			// 		// ...this._store.getState().items[productId].images,
			// 		downloadURL
			// 	]
			// });
		} catch (error) {
			const detailedError = new Error(
				`Error uploading product image: Error ${error}`
			);
			appStateService.logger.error(detailedError);
			throw detailedError;
		}
	}

	/**
	 * Generates information for a product based on an image of it.
	 * It consumes GPT-4 Vision model from OpenAI API, which
	 * might imply high processing costs.
	 *
	 * @param image The image to be processed.
	 * @param onComplete [Optional] The callback to be processed when the product is generated.
	 * @returns
	 */
	async generateInfoFromImage(
		image: File | Blob,
		onComplete?: undefined | ((item: IProduct) => Promise<void>)
	): Promise<IProduct> {
		try {
			let product: IProduct = {
				...new Product()
			};

			const aiProductData =
				await AIModels.productImageInterpreter.executor<IProduct>(
					{
						file: image,
						propMap:
							"{ " +
							"name: string, " +
							"description: string, " +
							"category: string, " +
							"brand: string " +
							// "brandDescription: string" +
							" }"
					},
					onComplete
				);

			product = { ...product, ...aiProductData };

			if (typeof onComplete === "function") await onComplete(product);

			return product;
		} catch (error) {
			const detailedError = new Error(
				`Error generating product metadata from image. Error: ${error}`
			);
			appStateService.logger.error(detailedError);
			throw detailedError;
		}
	}

	/**
	 * Gets the download URL of an image from Firebase Storage.
	 *
	 * @param imagePath The path of the image in Firebase Storage.
	 * @returns The download URL of the image.
	 */
	async getImageDownloadURL(imagePath: string): Promise<string> {
		const downloadURL =
			await appStateService.firebaseStorage.getFileDownloadURL(imagePath);

		return downloadURL;
	}

	/**
	 * Changes a product and re-sets the list of products in the slice state.
	 *
	 * @param product The product to be updated.
	 */
	async replaceStateListProduct(product: IProduct): Promise<void> {
		const updatedProduct = { ...product };
		const currentList = ProductSelectors.getProducts(store.getState());
		const newList = [...currentList];

		const itemIndex = newList.findIndex(
			(item) => item.id === updatedProduct.id
		);

		// Replace the product in the list, so to get the updated image list
		newList[itemIndex] = updatedProduct;

		await this.setList(newList);
	}

	/**
	 * Generates an AI-based Average Market Price research of a product, by its known Name.
	 * TODO: Implement support for optional additional metadata
	 * E.g. "Tênis Nike Air 3"
	 * 		-> will produce something like (currency) (value)
	 * 		-> "R$ 500,10"
	 *
	 * @param productName The name of the product to be market researched.
	 * @returns A promise with the value.
	 */
	async getAIMarketAveragePriceByProductName(
		productName: string
	): Promise<string> {
		return await AIModels.productAverageMarketPrice.executor(productName);
	}

	/**
	 * Generates an AI-based description of a given product, by its known name.
	 * E.g. "Red Nike Air Max 2"
	 * 		-> will produce something like
	 * 		-> "Nike Air Max 2 is a shoe for pros and outdoor sportswear."
	 *
	 * @param productName The name of the brand to be described.
	 * @returns A promise with the description.
	 */
	async getAIDescription(productName: string): Promise<string> {
		return await AIModels.productDescription.executor(productName);
	}
}

export { ProductService };
