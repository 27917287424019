import { translate } from "hooks/i18n";
import { IAppManagerService } from "interfaces";
// import { ReduxStoreService } from "./redux";
import { store } from "App";
import { actions } from "redux/reducers/appConfig/reducer";
import { MessageModalType } from "enums";

/**
 * Implements the application mainstream service layer.
 * Shared services are microservices of this class.
 * For instance:
 * * Message modal behaviors.
 * * Application general behaviors.
 */
export class AppManagerService implements IAppManagerService {
	/**
	 * Sets the Browser (navigator) top bar title based on title in the header tag of the page.
	 */
	setAppTitle(title: string): Promise<void> {
		window.document.title = title;

		return;
	}

	/**
	 * Shows a modal message of type "info".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showMessage(
		message: string,
		type: MessageModalType = MessageModalType.info,
		title?: undefined | string
	): Promise<void> {
		const titleTranslated = translate(
			!title ? "global.modal.message.default.title" : title
		);
		const messageTranslated = translate(message);

		this.setMessageModalType(type);
		this.setMessageModalTitle(titleTranslated);
		this.setMessageModalText(messageTranslated);
		this.setMessageModalOpen(true);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "info".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showInfo(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.info, title);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "warning".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showWarning(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.warning, title);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "error".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showError(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.error, title);

		return Promise.resolve();
	}

	/**
	 * Shows a modal message of type "success".
	 *
	 * @param message The message to be shown.
	 * @param title [Optional] The title of the message.
	 */
	showSuccess(message: string, title?: undefined | string): Promise<void> {
		this.showMessage(message, MessageModalType.success, title);

		return Promise.resolve();
	}

	/**
	 * Hides a modal message.
	 */
	hideMessage(): Promise<void> {
		this.setMessageModalOpen(false);

		return Promise.resolve();
	}

	/**
	 * Toggles the visibility of the app bar.
	 */
	toggleAppBar(): void {
		// inverts the state of the appBar.Hidden property
		store.dispatch(
			actions.setAppBarHidden(!store.getState().appConfig.appBar.hidden)
		);
	}

	/**
	 * Hides the app bar (set hidden to true).
	 */
	hideAppBar(): void {
		store.dispatch(actions.setAppBarHidden(true));
	}

	/**
	 * Shows the app bar (set hidden to false).
	 */
	showAppBar(): void {
		store.dispatch(actions.setAppBarHidden(false));
	}

	/**
	 * Sets the Title within the boundaries of the app bar.
	 */
	setAppBarTitle(title: string): void {
		store.dispatch(actions.setAppBarTitle(title));
	}

	/**
	 * Sets the loading state of the App Config.
	 *
	 * @param status The loading state.
	 */
	setLoading(status: boolean): void {
		store.dispatch(actions.setLoading(status));
	}

	/**
	 * Shows the loading state of the App Config.
	 */
	showLoader(): void {
		this.setLoading(true);
	}

	/**
	 * Hides the loading state of the App Config.
	 */
	hideLoader(): void {
		this.setLoading(false);
		this.resetLoader();
	}

	/**
	 * Sets the loading text of the App Config.
	 *
	 * @param text The loading text.
	 */
	setLoadingText(text: string): void {
		store.dispatch(actions.setLoadingText(text));
	}

	/**
	 * Resets the loading text of the App Config.
	 */
	resetLoader(): void {
		this.setLoading(false);
		this.setLoadingText("");
	}

	/**
	 * Sets the message modal type.
	 *
	 * @param type The message modal type.
	 */
	setMessageModalType(type: MessageModalType): void {
		store.dispatch(actions.setMessageModalType(type));
	}

	/**
	 * Sets whether the App's Message Modal is open.
	 *
	 * @param text The loading text.
	 */
	setMessageModalOpen(open: boolean): void {
		store.dispatch(actions.setMessageModalOpen(open));
	}

	/**
	 * Sets the message title of the App Config.
	 *
	 * @param text The message title.
	 */
	setMessageModalTitle(title: string): void {
		store.dispatch(actions.setMessageModalTitle(title));
	}

	/**
	 * Sets the message text of the App Config.
	 *
	 * @param text The message text.
	 */
	setMessageModalText(text: string): void {
		store.dispatch(actions.setMessageModalText(text));
	}
}
