import React, { useMemo, useRef } from "react";
import { Modal } from "components/shared/modal/Modal";
import { appStateService } from "App";
import { useSelector } from "react-redux";
import { UserSelectors } from "redux/selectors";
import { InputText } from "components/shared/input";

import "./UserProfileForm.scss";
import { Button } from "components/shared/button";

/**
 * Contract for the properties of the UserProfileForm component.
 */
interface IUserProfileFormProps {
	/**
	 * Determines whether the modal is open or not.
	 */
	open: boolean;
}

declare type SetterFunction = (
	propName: string,
	newValue: string | number | boolean
) => void;

const FieldGroup = (props) => {
	return <div className="field-group">{props.children}</div>;
};

interface IFieldGroupValueProps {
	id: string;
	name: string;
	setter: SetterFunction;
	isEditing: boolean;
	disabled?: undefined | boolean;
}

const FieldGroupValue: React.FC<IFieldGroupValueProps> = (props) => {
	const userProfileData = useSelector(UserSelectors.selectUserProfile);
	const handleChange =
		(setter: SetterFunction) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setter(event.target.name, event.target.value);
		};
	const value = useMemo(
		() => userProfileData[props.name],
		[userProfileData, props.name]
	);

	return (
		<div>
			{props.isEditing ? (
				<InputText
					id={props.id}
					name={props.name}
					value={value}
					onChange={handleChange(props.setter)}
					disabled={props.disabled}
				/>
			) : undefined !== value && value !== "" ? (
				value
			) : (
				"-"
			)}
		</div>
	);
};

/**
 * UserProfileForm Component, which displays the user's profile information.
 * It is also editable by enabling the mode.
 *
 * @param props IUserProfileFormProps
 * @returns React.FC<IUserProfileFormProps>
 */
const UserProfileForm: React.FC<IUserProfileFormProps> = (props) => {
	const titles = { form: "Meu Perfil", section: "Informações Pessoais" };
	const propTitles = {
		userName: "Nome",
		userDisplayName: "Nome Preferido",
		userMail: "E-mail",
		isAdmin: "Administrador"
	};
	const propNames = {
		userName: "name",
		userDisplayName: "displayName",
		userMail: "mail",
		isAdmin: "isAdmin"
	};
	const userProfileData = useSelector(UserSelectors.selectUserProfile);
	const originalProfileData = useRef(userProfileData);
	const isEditing = useSelector(UserSelectors.selectUserProfileFormEditing);

	const handleSave = () => {
		appStateService.user.updateItem(userProfileData, async (item) => {
			appStateService.user.setProfileFormEditing(false);
			appStateService.user.setProfileFormOpen(false);
		});
	};

	const handleEdit = () => {
		appStateService.user.setProfileFormEditing(true);
	};

	const handleCancel = () => {
		// Restores the original data to the form, if anything changed.
		appStateService.user.setName(originalProfileData.current.name);
		appStateService.user.setDisplayName(
			originalProfileData.current.displayName
		);
		appStateService.user.setEmail(originalProfileData.current.mail);

		// Closes the form.
		appStateService.user.setProfileFormEditing(false);
		appStateService.user.setProfileFormOpen(false);
	};

	return (
		<Modal
			title={titles.form}
			wide
			open={props.open}
			data={{
				buttons: {
					confirm: {
						text: "Aplicar",
						onClick: handleSave,
						disabled: !isEditing
					},
					cancel: { text: "Cancelar", onClick: handleCancel }
				}
			}}
		>
			<div className="userProfileForm">
				<div className="section">
					<h3 className="section_title">{titles.section}</h3>
					<form onSubmit={() => {}}>
						<FieldGroup>
							<label htmlFor={propNames.userName}>
								{propTitles.userName}
							</label>
							<FieldGroupValue
								id={propNames.userName}
								name={propNames.userName}
								isEditing={isEditing}
								setter={(prop, val) =>
									appStateService.user.setName(val.toString())
								}
							/>
						</FieldGroup>
						<FieldGroup>
							<label htmlFor={propNames.userDisplayName}>
								{propTitles.userDisplayName}
							</label>
							<FieldGroupValue
								id={propNames.userDisplayName}
								name={propNames.userDisplayName}
								isEditing={isEditing}
								setter={(prop, val) =>
									appStateService.user.setDisplayName(
										val.toString()
									)
								}
							/>
						</FieldGroup>
						<FieldGroup>
							<label htmlFor={propNames.userMail}>
								{propTitles.userMail}
							</label>
							<a href={`mailto:${userProfileData.mail}`}>
								{userProfileData.mail}
							</a>
							{/* <FieldGroupValue
								id={propNames.userMail}
								name={propNames.userMail}
								isEditing={isEditing}
								setter={(prop, val) =>
									appStateService.user.setEmail(
										val.toString()
									)
								}
								disabled
							/> */}
						</FieldGroup>
						<FieldGroup>
							<label htmlFor={propNames.isAdmin}>
								{propTitles.isAdmin}
							</label>
							{userProfileData?.roles?.admin ? "Sim" : "Não"}
						</FieldGroup>
					</form>
					{!isEditing ? (
						<Button text={"Editar Perfil"} onClick={handleEdit} />
					) : null}
				</div>
			</div>
		</Modal>
	);
};

export { UserProfileForm, IUserProfileFormProps };
