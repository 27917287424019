import React from "react";

import { useSelector } from "react-redux";
import { AppConfigSelectors, UserSelectors } from "redux/selectors";

import "./HeaderStyles.scss";

interface IHeaderSpacerProps {}

/**
 * Header spacer component
 */
const HeaderSpacer: React.FC<IHeaderSpacerProps> = (props) => {
	const isAuthenticated = useSelector(UserSelectors.selectAuthenticated);
	const isAppBarHidden = useSelector(AppConfigSelectors.getAppBarHidden);

	return isAuthenticated && !isAppBarHidden ? (
		<div className="header-spacer" />
	) : null;
};

export { HeaderSpacer, IHeaderSpacerProps };
