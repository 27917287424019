/**
 * Enum for the status of a Client Order within the system.
 */
export enum ClientOrderStatus {
	new = "new",
	needsPayment = "needsPayment",
	confirmed = "confirmed",
	pending = "pending",
	inProgress = "inProgress",
	packaging = "packaging",
	readyToDeliver = "readyToDeliver",
	inDelivery = "inDelivery",
	delivered = "delivered",
	completed = "completed",
	cancelled = "cancelled",
	rejected = "rejected", // TODO: Why would it be rejected?
	error = "error"
}
