import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import { UserSelectors } from "redux/selectors";
import { appStateService } from "App";
import { useTranslate } from "hooks/i18n";

/**
 * Contract for the properties provided to the AppAuthentication component.
 */
interface IAppAuthenticationProps {
	key?: undefined | string;
	children: JSX.Element | React.ReactNode;
}

/**
 * Application authentication layer, which abstracts
 * the necessary operations of the authentication ecosystem.
 */
function AppAuthentication({
	children,
	key
}: IAppAuthenticationProps): JSX.Element {
	const authMonitor = useRef(appStateService.auth.monitorAuthState);
	const authenticating = useSelector(UserSelectors.selectAuthenticating);
	const authenticated = useSelector(UserSelectors.selectAuthenticated);
	const loadingText = useTranslate("global.loading");

	// // Verifies the authentication state on mount
	// useEffect(() => {
	// 	async function checkAuth() {
	// 		const hasValidAuthentication =
	// 			authenticated && (await appStateService.auth.verifyLocalAuth());

	// 		if (!authenticating && !hasValidAuthentication) {
	// 			console.log(translate("app.authentication.request.message"));

	// 			// TODO: Fix this redirect
	// 			navigate(Routes.Login);
	// 		}
	// 	}

	// 	checkAuth();
	// }, [authenticating, authenticated]);

	// Starts up a monitor for the authentication state, which can identify changes from server-side as well.
	useEffect(() => {
		const unsubscribeMonitor = authMonitor.current();

		return () => {
			unsubscribeMonitor();
		};
	}, [authMonitor]);

	// TODO: Improve the loader and adjust the display needs for it when the app is loading authentication
	return authenticated ? (
		<>{children}</>
	) : authenticating ? (
		<>{loadingText}</>
	) : null;
}

export { AppAuthentication, IAppAuthenticationProps };
