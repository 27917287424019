/**
 * Interface for the Home page component test automation.
 */
interface IHomeTestIds {
	Container: string;
	Dashboard: string;
	Menu: string;
	Title: string;
}

/**
 * Dictionary of test ids for the Home page component test automation.
 */
const HomeTestIds: IHomeTestIds = {
	Container: "page-home-container",
	Dashboard: "page-home-dashboard",
	Menu: "page-home-menu",
	Title: "page-home-title"
};

export { HomeTestIds, IHomeTestIds };
