// navigator.ts
import { createRef, MutableRefObject } from "react";

// Define the shape of our navigate function
type NavigateFunction = (
	to: string,
	options?: { replace?: boolean; state?: any }
) => void;

// Create a ref which holds the navigate function. It's initially null.
const navigationRef: MutableRefObject<NavigateFunction | null> = createRef();

/**
 * Set the navigator object reference.
 *
 * @param navigateFunction
 */
export const setNavigation = (navigateFunction: NavigateFunction) => {
	navigationRef.current = navigateFunction;
};

/**
 * Navigate to a specific route.
 *
 * @param to Where to navigate to.
 * @param options [Optional] Navigation options.
 */
export const navigate = (
	to: string,
	options?: { replace?: boolean; state?: any }
) => {
	// Call the navigate function if it's available
	if (navigationRef.current) {
		navigationRef.current(to, options);
	} else {
		// Handle or throw error when navigation function is not available
		throw new Error("Navigation function is not defined yet");
	}
};
