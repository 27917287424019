import { MessageModalType } from "enums";
import { IdentifiedItem } from "interfaces/index";

/**
 * AppConfig basic interface.
 */
interface IAppConfig extends IdentifiedItem {
	id: string;
	appBar: {
		hidden: boolean;
		title: string;
	};
	loader: {
		loading: boolean;
		loadingText: string;
	};
	messageModal: {
		type: MessageModalType;
		open: boolean;
		title: string;
		text: string;
	};
}

/**
 * AppConfig Redux slice interface.
 */
interface IAppConfigSlice extends IAppConfig {}

/**
 * AppConfig factory class.
 */
class AppConfig implements IAppConfig {
	id = new Date().getTime().toString();
	appBar = {
		hidden: false,
		title: ""
	};
	loader = {
		loading: false,
		loadingText: ""
	};
	messageModal = {
		type: MessageModalType.info,
		open: false,
		title: "",
		text: ""
	};

	constructor(data?: undefined | IAppConfig) {
		this.id = data?.id ?? this.id;
		this.appBar = {
			hidden: data?.appBar?.hidden ?? false,
			title: data?.appBar?.title ?? ""
		};
		this.loader = {
			loading: data?.loader?.loading ?? false,
			loadingText: data?.loader?.loadingText ?? ""
		};
		this.messageModal = {
			type: data?.messageModal?.type ?? MessageModalType.info,
			open: data?.messageModal?.open ?? false,
			title: data?.messageModal?.title ?? "",
			text: data?.messageModal?.text ?? ""
		};
	}
}

/**
 * User profile initial state.
 */
const appConfigInitialState: IAppConfigSlice = {
	id: "",
	appBar: {
		hidden: false,
		title: ""
	},
	loader: {
		loading: false,
		loadingText: ""
	},
	messageModal: {
		type: MessageModalType.info,
		open: false,
		title: "N.D.",
		text: "N.D."
	}
};

export { IAppConfig, AppConfig, IAppConfigSlice, appConfigInitialState };
