// TODO: Define remote call params for service URL via environment variables.
const _url =
	process.env.NODE_ENV === "development"
		? "http://127.0.0.1:5001/<project-id>/<region-id>/"
		: "https://<region-id>-<project-id>.cloudfunctions.net/";

const _project = "bebase-web";
const _region = "us-central1";

/**
 * Basic Service implementation to call Firebase Functions.
 */
class FirebaseFunctionsService {
	private baseURL: string;
	private functionName: string;

	/**
	 * Constructor for the Firebase Functions Service.
	 *
	 * @param functionName The name of the function to be called.
	 */
	constructor(functionName: string) {
		this.baseURL = _url
			.replace("<region-id>", _region)
			.replace("<project-id>", _project);
		this.functionName = functionName;
	}

	/**
	 * Calls the Firebase Function, specified during construction.
	 *
	 * @param data The data to be sent to the function.
	 * @returns The response from the function.
	 */
	public callService = async (data: any) => {
		const functionURL = `${this.baseURL}${this.functionName}`;
		const response = await fetch(functionURL, {
			method: "POST",
			body: JSON.stringify(data)
		});
		return response;
	};
}

export { FirebaseFunctionsService };
