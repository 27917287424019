import { appStateService, store } from "App";
import { IAIModelProcess } from "models/index";
import { UserSelectors } from "redux/selectors";
import {
	OpenAIModelVersion,
	OpenAIService
} from "services/openai/OpenAIService";
import { TrainingData } from "services/openai";

/**
 * Class for managing and consuming Text Generation resources from OpenAI API.
 */
class OpenAITextCompletionService {
	_defaultMessages = [
		{
			role: "system",
			content: TrainingData.assumptions.reduce(
				(prev, current) => `${prev} ${current}`
			)
		}
	];
	_presetMessages = [];

	constructor() {
		appStateService.logger.log("OpenAI Text Completion Service initiated.");
	}

	/**
	 * Gets the training data for a given model.
	 *
	 * @param modelName The name of the model to get the training data.
	 * @returns The training data for the given model.
	 */
	getPreTrainedData(modelName: string): string[] {
		return TrainingData[modelName] ?? ["Error", "invalid-model-name"];
	}

	/**
	 * Adds preset messages to the instance for usage during lifecycle.
	 *
	 * @param messages The messages to be added.
	 * @param reset [Optional] Whether to reset the preset messages or not. Defaults to false.
	 * @returns The instance itself.
	 */
	addPresetMessages(messages: string[], reset: boolean = false) {
		if (reset) this._presetMessages = [...messages];
		else this._presetMessages = [...this._presetMessages, ...messages];

		return this;
	}

	/**
	 * Clears the preset messages.
	 *
	 * @returns The instance itself.
	 */
	clearPresetMessages() {
		this._presetMessages = [];

		return this;
	}

	/**
	 * Creates a fine-tuned model based on a given collection of prompts.
	 *
	 * @param prompts The collection of prompts to be used in the fine-tuning.
	 * @param modelVersion [Optional] The model version to be used in the fine-tuning. Defaults to GPT-4-0613.
	 * @returns A promise with the fine-tuned model ID.
	 */
	async createFineTunedModel(
		modelName: string,
		prompts: string[],
		modelVersion:
			| undefined
			| OpenAIModelVersion = OpenAIModelVersion.GPT4_0613
	): Promise<string> {
		try {
			const userId =
				UserSelectors.selectUserProfileId(store.getState()) ??
				"anonymous";

			const fineTunedFile = await OpenAIService.files.create({
				purpose: "fine-tune",
				file: new File(
					prompts,
					`${modelName}-${userId}-fine-tuning.jsonl`
				)
			});

			const fineTunedModel = await OpenAIService.fineTuning.jobs.create({
				// prompts: prompts,
				// user: userId,
				training_file: fineTunedFile.filename,
				model: modelVersion
			});

			return fineTunedModel.id;
		} catch (error) {
			const detailedError = new Error(
				`Error creating fine-tuned model: Error ${error}`
			);
			appStateService.logger.error(detailedError);
			throw detailedError;
		}
	}

	/**
	 * Gets a text completion from OpenAI API.
	 * Uses GPT-4 as default model, so, using it can result in costly operations.
	 *
	 * @param prompt The prompt to be used in the completion.
	 * @param modelId The model ID to be used in the completion.
	 * @param onComplete [Optional] Callback to be called when the completion is ready.
	 * @param openAIModelVersion [Optional] The model version to be used in the completion.
	 * @returns A promise with the completion.
	 */
	async getCompletion(
		prompt: string,
		modelId: string,
		onComplete: undefined | ((completionResult: string) => Promise<void>),
		openAIModelVersion:
			| undefined
			| OpenAIModelVersion = OpenAIModelVersion.GPT4
	): Promise<string> {
		const modelProcessService =
			appStateService.company.aiModelProcess.get();
		let response: string;
		const userId =
			UserSelectors.selectUserProfileId(store.getState()) ?? "anonymous";
		let modelProcess: IAIModelProcess;

		try {
			modelProcess = await modelProcessService.logModelProcess(
				`getCompletion: ${prompt}`,
				userId,
				modelId,
				openAIModelVersion
			);

			// Advances to the next step in the model process.
			await modelProcessService.logModelProcessProcessing(modelProcess);

			// const gptResponse = await openAI.completions.create({
			const gptResponse = await OpenAIService.chat.completions.create({
				messages: [
					...this._defaultMessages,
					...this._presetMessages,
					{ role: "user", name: "User", content: prompt }
				],
				user: userId,
				model: openAIModelVersion
			});

			const responseValid = gptResponse.choices.length > 0;

			if (!responseValid) {
				appStateService.logger.error(
					new Error(
						"OpenAI Text Completion Service: Invalid response. Errored object: " +
							JSON.stringify(gptResponse)
					)
				);
				response = "Error: Invalid response.";
			} else {
				response = gptResponse.choices[0].message.content;

				if (typeof onComplete === "function")
					await onComplete(response);
			}

			// Marks the process as completed
			await modelProcessService.logModelProcessCompletion(
				modelProcess,
				response,
				gptResponse.usage
			);

			return response;
		} catch (error) {
			// Marks the process as errored
			await modelProcessService.logModelProcessError(modelProcess, error);

			const detailedError = new Error(
				`Error getting completion: Error ${error}`
			);
			appStateService.logger.error(detailedError);
			throw detailedError;

			// return "";
		}
	}

	/**
	 * Gets a text completion based on a query from OpenAI API.
	 * Uses GPT-4 as default model, so, using it can result in costly operations.
	 *
	 * @param textModel The text model to be used in the completion.
	 * @param modelId The model ID to be used in the completion.
	 * @param openAIModelVersion [Optional] The model version to be used in the completion.
	 * @returns
	 */
	async getDescription(
		textModel: string,
		modelId: string,
		openAIModelVersion:
			| undefined
			| OpenAIModelVersion = OpenAIModelVersion.GPT4
	): Promise<string> {
		try {
			if (!textModel || textModel.length === 0) throw Error("");
			let result: string;

			await this.getCompletion(
				textModel,
				modelId,
				async (response) => {
					result = response;
				},
				openAIModelVersion
			);

			return result;
		} catch (error) {
			const detailedError = new Error(
				`Error getting description: Error ${error}`
			);
			appStateService.logger.error(detailedError);
			// throw detailedError;

			return "";
		}
	}
}

export { OpenAITextCompletionService };
