import React, { Component } from "react";

/**
 * Error Boundary State definition
 */
interface IErrorBoundaryState {
	hasError: boolean;
	error: Error | null;
	errorInfo: React.ErrorInfo | null;
}

/**
 * Error Boundary Component
 */
class ErrorBoundary extends Component<
	{ componentName: string; children: React.ReactNode },
	IErrorBoundaryState
> {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null, errorInfo: null };
	}

	// This lifecycle method is invoked after an error has been thrown by a descendant component.
	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	// This lifecycle method is invoked after an error has been thrown by a descendant component.
	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);

		// Store the error and error info in the state
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div style={{ padding: "10px" }}>
					<h1 style={{ marginBottom: "10px" }}>
						Something went wrong.
					</h1>
					<details style={{ whiteSpace: "pre-wrap" }}>
						{this.state.error && this.state.error.toString()}
						<br />
						{this.state.errorInfo &&
							this.state.errorInfo.componentStack}
					</details>
				</div>
			);
		}

		// Normally, just render children
		return this.props.children;
	}
}

export { ErrorBoundary, IErrorBoundaryState };
