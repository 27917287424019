export const dictionary = {
	"app.authentication.request.message": "Please, authenticate.",
	"app.title": "BeeBase",
	"app.description": "This is the BeeBase App",
	"app.welcome": "Welcome to BeeBase!",
	"app.bar.title": "BeeBase Admin",
	"app.bar.button.logoff": "Sign Out",
	"app.bar.button.logoff.confirm": "Are you sure you want to sign out?",
	"global.dropdown.defaultItem.placeholder": "<None>",
	"global.loading": "Loading...",
	"global.logout": "Logout",
	"global.login": "Login",
	"global.list.action.add": "Add",
	"global.list.action.edit": "Edit",
	"global.list.action.save": "Save",
	"global.list.action.cancel": "Cancel",
	"global.list.action.delete": "Delete",
	"global.list.empty": "No items to show.",
	"global.list.count.empty": "No item",
	"global.list.count.single": "1 item",
	"global.list.count.plural": "${count} items",
	"global.list.filter.placeholder": "To filter, type here..",
	"global.userProfile.button.profile": "Profile",
	"global.userProfile.button.signOut": "Sign Out",
	"list.categories.name": "Categories",
	"list.products.name": "Products",
	"list.brands.name": "Brands",
	"page.home.title": "BeeBase Admin",
	"page.home.welcome": "Welcome, ${username}.",
	"page.login.title": "Login",
	"page.login.photoCredit": "Photo by ${author}",
	"page.login.form.title": "Login",
	"page.login.form.email": "Email",
	"page.login.form.empty.mail": "Please, inform the email.",
	"page.login.form.password": "Password",
	"page.login.form.passwordResetEmailSent": "Password reset email sent.",
	"page.login.form.resetPassword": "I forgot my password",
	"page.login.form.submit": "Sign In",
	"page.login.form.submit.passwordResetEmail": "Send Password Reset mail",
	"page.login.form.submit.error.invalidMail":
		"Invalid email format. Please, verify it.",
	"page.login.form.submit.error.mailNotFound":
		"Email not found. Please, verify it.",
	"page.login.form.submit.error":
		"Invalid user and/or password. Please, verify it and try again.",
	"page.login.form.submit.error.offline":
		"You are offline. Please, reconnect, then try again.",
	"page.categoriesList.title": "Categories",
	"page.clientOrderList.title": "Client Orders",
	"page.productsList.title": "Products",
	"page.suppliersList.title": "Suppliers",
	"page.brandsList.title": "Brands",
	"page.landingMails.title": "Mails (Landing Page)",
	"page.stockList.title": "Products Stock",
	"page.home.chat-shortcut": "Initiate Bee AI Chat",
	"page.orderList.items.count": "${count} items.",
	"page.order.internal.pageTitle.new": "New Order",
	"page.order.internal.pageTitle.edit": "View Order",
	"page.order.management.list.empty": "No orders here",
	"page.order.management.order.item.action.idClick":
		"Click to navigate to order details",
	"abbrev.unit": "un",
	"general.notAvailable": "N/A",
	"order.list.status.new":
		"This order was just created. Awaiting customer action.",
	"order.list.status.needsPayment":
		"This order is awaiting payment by the customer.",
	"order.list.status.confirmed":
		"Payment for this order has been confirmed. Processing payment and stock movements.",
	"order.list.status.pending":
		"This order has been paid, confirmed, therefore it is awaiting internal movement.",
	"order.list.status.inProgress":
		"This order is in progress, but has not yet been packaged for shipping.",
	"order.list.status.packaging": "This order is being packaged for shipping.",
	"order.list.status.readyToDeliver":
		"This order is ready for delivery, therefore, it awaits a delivery person.",
	"order.list.status.inDelivery":
		"This order is being delivered by ${deliveryPersonName}.",
	"order.list.status.delivered":
		"This order has been successfully delivered. Awaiting customer confirmation.",
	"order.list.status.completed":
		"This order has been successfully completed.",
	"global.modal.message.action.cancel": "Cancel",
	"global.modal.message.action.confirm": "Ok",
	"global.modal.message.default.title": "Warning",
	"global.button.confirm": "Confirm",
	"global.button.cancel": "Cancel",
	"global.button.save": "Save",
	"global.button.close": "Close",
	"page.order.internal.checkout.criticalError":
		"Cancelled: Critical error during checkout process. Please, contact support.",
	"page.order.internal.checkout.success": "Order created successfully",
	"page.order.internal.modalTitle.new": "New Order",
	"page.order.internal.modalTitle.edit": "Order ${orderId}"
};
