import { IListState } from "interfaces/index";

/**
 * List state initial state.
 */
export const listInitialState: IListState<any> = {
	editing: false,
	selected: [],
	filter: "",
	filterProp: {
		field: "",
		label: ""
	},
	sortProp: {
		field: "",
		label: ""
	},
	sortDirection: ""
};
