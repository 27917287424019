import { generatePath } from "react-router-dom";

/**
 * An interface to define the routes of the application.
 */
export interface IRoutes {
	All: string;
	BeeAIChat: string;
	Landing: string;
	Home: string;
	Error404: string;
	Login: string;
	LoginPasswdRecovery: string;
	LandingMails: string;
	ExternalSite: string;
	SignUp: string;
	OnBoarding: string;
	Profile: string;
	CategoriesList: string;
	BrandsList: string;
	ProductsList: string;
	ProductStockList: string;
	SuppliersList: string;
	SellOrderForm: string;
	OrderManagement: string;
	ClientOrderList: string;
	ClientOrder: (params?: undefined | { orderId: string }) => string;
}

/**
 * Centered Route table, respecting an alphabetical order.
 */
export const Routes: IRoutes = {
	All: "*",
	BeeAIChat: "/bee-ai/chat",
	Landing: "/landing",
	Home: "/home",
	Error404: "/e404",
	Login: "/login",
	LoginPasswdRecovery: "/login/passwd-recovery",
	LandingMails: "/landing-page/mail-list",
	ExternalSite: "/site",
	SignUp: "/sign-up",
	OnBoarding: "/on-boarding/:customerId",
	// TODO: Create pages below
	Profile: "/profile",
	CategoriesList: "/categories/list",
	BrandsList: "/brands/list",
	ProductsList: "/products/list",
	ProductStockList: "/stock/list",
	SuppliersList: "/suppliers/list",
	SellOrderForm: "/sell-order/form/new",
	OrderManagement: "/order-management",
	ClientOrderList: "/orders/client",
	ClientOrder: (params = undefined) =>
		!params
			? "/orders/client/:orderId"
			: generatePath("/orders/client/:orderId", params)
};
