/**
 * Interface for the test ids of the Landing page component.
 */
interface ILandingTestIds {
	Root: string;
	Image: string;
	Announcement: string;
	LaunchDate: string;
	Description: string;
	// Name: string;
	Button: {
		// Subscribe: string;
		Instagram: string;
		Facebook: string;
		WhatsApp: string;
	};
	Contact: string;
}

/**
 * Dictionary of test ids for the Landing page component test automation.
 */
const LandingTestIds: ILandingTestIds = {
	Root: "LandingPage",
	Image: "landingPage.image",
	Announcement: "landingPage.announcement",
	LaunchDate: "landingPage.launch",
	Description: "landingPage.description",
	// Name: "landingPage.name",
	Button: {
		// Subscribe: "landingPage.subscribe",
		Instagram: "landingPage.button.instagram",
		Facebook: "landingPage.button.facebook",
		WhatsApp: "landingPage.button.whatsapp"
	},
	Contact: "landingPage.contact"
};

export { LandingTestIds, ILandingTestIds };
