/**
 * Application's Redux slice names
 */
export const ReduxSlices = {
	AppConfig: "appConfig",
	Clients: "clients",
	ClientOrders: "clientOrders",
	Companies: "companies",
	UserProfile: "user",
	Products: "products",
	Categories: "categories",
	Suppliers: "suppliers",
	Brands: "brands",
	LandingPage: "landingPage",
	ClientOrderForm: "clientOrderForm",
	StockList: "stockList",
	AIModelProcesses: "aiModelProcesses"
	// TODO: Cart: "cart",
	// TODO: Orders: "orders",
	// TODO: Admin: "admin"
};
