import React from "react";

import "./Modal.scss";

/**
 * Contract for the data provided to the Modal component.
 */
interface IModalData {
	content?: undefined | null | string;
	buttons: {
		confirm: {
			text: string;
			onClick: () => void;
			disabled?: undefined | boolean;
		};
		cancel: {
			text: string;
			onClick: () => void;
			disabled?: undefined | boolean;
		};
	};
}

/**
 * Contract for the properties provided to the Modal component.
 */
interface IModalProps {
	title: string;
	data: IModalData;
	children?: undefined | null | React.ReactNode | JSX.Element;
	open: boolean;
	wide?: undefined | boolean;
}

/**
 * Modal component.
 *
 * @param props The properties of the component.
 * @returns
 */
export function Modal(props: IModalProps): JSX.Element {
	const { data, title, wide } = props;
	const modalWidth = wide ? "75vw" : "";

	return props.open ? (
		<div className="modal">
			<div className="modal__container">
				<div className="modal__title">
					{title ?? "<undefined-title>"}
				</div>
				<div className="modal__content" style={{ width: modalWidth }}>
					{props?.children ?? data.content}
				</div>
				<div className="modal__buttons">
					<button
						className="modal__button modal__button_cancel"
						onClick={data.buttons.cancel.onClick}
						disabled={data.buttons.cancel?.disabled ?? undefined}
					>
						{data.buttons.cancel.text}
					</button>
					<button
						className="modal__button modal__button_confirm"
						onClick={data.buttons.confirm.onClick}
						disabled={data.buttons.confirm?.disabled ?? undefined}
					>
						{data.buttons.confirm.text}
					</button>
				</div>
			</div>
			<div className="modal__backdrop"></div>
		</div>
	) : null;
}
