import { ILazyService } from "interfaces/services";

/**
 * Lazy service class.
 */
class LazyService<T> implements ILazyService<T> {
	_serviceType: null | { new (): T } = null;
	_serviceInstance: null | T = null;
	_startUpTime: null | Date = null;

	constructor(type: { new (): T }) {
		this._serviceType = type;
	}

	/**
	 * Starts a service.
	 */
	start(): Promise<void> {
		this._startUpTime = new Date();
		return Promise.resolve();
	}

	/**
	 * Stops a service.
	 */
	stop(): Promise<void> {
		this._startUpTime = null;
		return Promise.resolve();
	}

	/**
	 * Gets the service instance.
	 * @returns The service instance.
	 */
	get(): T {
		this._serviceInstance = new this._serviceType();

		return this._serviceInstance;
	}
}

export { LazyService };
