import { FirebaseFunctionsService } from "services/firebase/functions";

/**
 * Creates a reference to access the functions/UserSignUp as an API Service.
 */
class UserSignUpService extends FirebaseFunctionsService {
	constructor() {
		super("userSignUp");
	}
}

export { UserSignUpService };
