import React from "react";

import { IInputBaseProps, InputBase } from "./InputBase";

/**
 * InputValue props contract interface.
 */
interface IInputTextProps extends IInputBaseProps {}

/**
 * InputValue shared component.
 *
 * @param props IInputValueProps
 * @returns
 */
function InputText(props: IInputTextProps): JSX.Element {
	// const isRequired = props.required ?? false;

	return (
		<InputBase
			type="text"
			value={props.value}
			onChange={props.onChange}
			id={props.id ?? undefined}
			disabled={props.disabled ?? undefined}
			{...props}
		/>
	);
}

export { InputText, IInputTextProps };
