import { useKeyDown } from "./useKeyDown";

/**
 * React hook for capturing Enter key down events,
 * re-using the useKeyDown hook.
 *
 * @param callback - The callback to be executed when the Enter key is pressed.
 * @param target - [Optional] The target element to listen for the keydown event. Defaults to Window element
 */
export function useEnterKeyDown<T>(
	callback: (e?: any) => void,
	target: HTMLElement | Window | Document = window
): T {
	useKeyDown("Enter", callback, target);

	return null;
}
