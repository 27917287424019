import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";

enum DocumentType {
	CPF = "CPF"
	// CNPJ = "CNPJ"
}

/**
 * Client basic interface.
 */
interface IClient extends IFirebaseItem {
	name: string;
	documentId: string;
	documentType: DocumentType;
	mail: string;
	phone: string;

	address: string;
	number: string;
	borough: string;
	city: string;
	state: string;
	country: string;
	postCode: string;
}

/**
 * Client Redux slice interface.
 */
interface IClientSlice extends ISliceList<IClient> {}

/**
 * Client factory class.
 */
class Client implements IClient {
	id: string = "";

	documentId: string = "";
	documentType: DocumentType = DocumentType.CPF;
	name: string = "";
	mail: string = "";
	phone: string = "";

	address: string = "";
	number: string = "";
	borough: string = "";
	city: string = "";
	state: string = "";
	country: string = "";
	postCode: string = "";

	deleted: boolean = false;
	timestamp: Timestamp;
	lastChangedBy?: undefined | string = "";

	constructor(data?: undefined | IClient) {
		this.id = data?.id ?? "";

		this.documentId = data?.documentId ?? "";
		this.documentType = data?.documentType ?? DocumentType.CPF;
		this.name = data?.name ?? "";
		this.mail = data?.mail ?? "";
		this.phone = data?.phone ?? "";

		this.address = data?.address ?? "";
		this.number = data?.number ?? "";
		this.borough = data?.borough ?? "";
		this.city = data?.city ?? "";
		this.state = data?.state ?? "";
		this.country = data?.country ?? "";
		this.postCode = data?.postCode ?? "";

		this.deleted = data?.deleted ?? false;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.lastChangedBy = data?.lastChangedBy ?? "";
	}
}

const clientsInitialState: IClientSlice = {
	list: [],
	loading: false,
	queried: false,
	editing: false,
	editingList: []
	// error: "",
	// searchText: ""
};

export { clientsInitialState, IClient, IClientSlice, Client };
