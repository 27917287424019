import {
	getStorage,
	ref,
	uploadString,
	uploadBytes,
	getDownloadURL
} from "@firebase/storage";

/* Firebase Storage accepted string formats */
import type { StringFormat } from "@firebase/storage-types";
import { appStateService } from "App";
import { firebaseService } from "./FirebaseService";

/**
 * Base Firebase Storage Service class configuration interface.
 */
interface IFirebaseStorageServiceConfig {
	baseUrl: string;
	defaultStringContentFormat: StringFormat;
	defaultStringContentType: string;
}

/**
 * Base Firebase Storage Service class configuration,
 * Which groups and centralizes common Storage configurations.
 */
const FirebaseStorageServiceConfig: IFirebaseStorageServiceConfig = {
	baseUrl: "temp",
	defaultStringContentFormat: "base64",
	defaultStringContentType: "text/plain"
};

/**
 * Base Firebase Storage Service class,
 * Which groups and centralizes common Storage functionalities.
 * Also, accepts the use of a subpath, which can be used to group files in a folder-like structure.
 */
class FirebaseStorageService {
	_storage = firebaseService.storage;
	_subPath: string = FirebaseStorageServiceConfig.baseUrl;
	_ref = ref(this._storage, this._subPath);

	constructor(subPath: string = FirebaseStorageServiceConfig.baseUrl) {
		// Reconfigures the ref, in case there is a different subpath to follow.
		if (subPath !== FirebaseStorageServiceConfig.baseUrl) {
			this._subPath = subPath;
			this._ref = ref(this._storage, this._subPath);
		}
	}

	/**
	 * Sets the subpath to be used in the service,
	 * as well as the ref to be used in the service.
	 *
	 * @param subPath The subpath to be used in the service.
	 * @param append [Optional] Whether to append the subpath to the current subpath or not.
	 */
	setSubPath(subPath: string, append: boolean = false): string {
		if (append) this._subPath = `${this._subPath}/${subPath}`;
		else this._subPath = subPath;

		this._ref = ref(this._storage, this._subPath);

		return this._subPath;
	}

	/**
	 * Upload a file to Firebase Storage
	 *
	 * @param {string} content - The content of the file to upload
	 * @param {string} filePath - The local path of the file to upload
	 * @param {string} destination - The destination path of the file in Firebase Storage
	 * @param {string} contentType - [Optional] The content type of the file to upload
	 * @param {StringFormat} contentFormat - [Optional] The content format of the file to upload
	 */
	async uploadFile(
		file: File,
		destination: string,
		contentType: string = FirebaseStorageServiceConfig.defaultStringContentType,
		contentFormat: StringFormat = FirebaseStorageServiceConfig.defaultStringContentFormat
	): Promise<string> {
		try {
			const filePath = file.name;
			const destinationPath = `${this._subPath}/${destination}`;
			const storageRef = ref(this._storage, destinationPath);

			const result = await uploadBytes(storageRef, file, {
				contentType: contentType,
				customMetadata: {
					originalPath: filePath
				}
			});

			appStateService.logger.log(
				`File "${filePath}" uploaded successfully to "${destinationPath}".`
			);

			const imagePath = result.metadata.fullPath;

			return imagePath;
		} catch (error) {
			appStateService.logger.error(
				new Error(`Error uploading file. Error: ${error}`)
			);
			throw error;
		}
	}

	/**
	 * Upload a file to Firebase Storage
	 *
	 * @param {string} content - The content of the file to upload
	 * @param {string} filePath - The local path of the file to upload
	 * @param {string} destination - The destination path of the file in Firebase Storage
	 * @param {string} contentType - [Optional] The content type of the file to upload
	 * @param {StringFormat} contentFormat - [Optional] The content format of the file to upload
	 */
	async uploadString(
		content: string,
		filePath: string,
		destination: string,
		contentType: string = FirebaseStorageServiceConfig.defaultStringContentType,
		contentFormat: StringFormat = FirebaseStorageServiceConfig.defaultStringContentFormat
	): Promise<void> {
		try {
			const destinationPath = `${this._subPath}/${destination}`;
			const storageRef = ref(this._storage, destinationPath);

			await uploadString(storageRef, content, "base64", {
				contentType: contentType,
				customMetadata: {
					originalPath: filePath
				}
			});

			appStateService.logger.log(
				`File "${filePath}" uploaded successfully to "${destinationPath}".`
			);
		} catch (error) {
			appStateService.logger.error(
				new Error(`Error uploading file. Error: ${error}`)
			);
			throw error;
		}
	}

	/**
	 * Download a file from Firebase Storage
	 *
	 * @param {string} source - The source path of the file in Firebase Storage
	 * @param {string} destination - The local path of the file to download
	 *
	 * @returns The download URL of the file.
	 */
	async getFileDownloadURL(filePath: string): Promise<string> {
		const sourcePath = `${
			filePath.startsWith(this._subPath) ? "" : `${this._subPath}/`
		}${filePath}`;

		try {
			const storageRef = ref(this._storage, sourcePath);
			const downloadURL = await getDownloadURL(storageRef);

			appStateService.logger.log(
				`File "${sourcePath}" can be downloaded via "${downloadURL}".`
			);

			return downloadURL;
		} catch (error) {
			appStateService.logger.error(
				new Error(
					`Error generating download URL for file "${sourcePath}". Error: ${error}`
				)
			);
			throw error;
		}
	}
}

export {
	FirebaseStorageService,
	IFirebaseStorageServiceConfig,
	FirebaseStorageServiceConfig // TODO: Decide whether to export or keep it internal
};
