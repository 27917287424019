import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import { useSelector } from "react-redux";

import { appStateService } from "App";
import { UserSelectors } from "redux/selectors";
import { useTranslate } from "hooks/i18n";
import { Overlay } from "components/shared/overlay";
import { AppBarTestIds } from "static/test";

import "./UserProfileButton.scss";
import { Dropdown, IOption } from "components/shared/dropdown";
import { IDropdownOption } from "@fluentui/react";
import { CompanySelectors } from "redux/selectors/companies/companiesSelectors";

/**
 * Contract for the properties of the UserProfileButton component.
 */
interface IUserProfileProps {}

/**
 * Component for organizing the user profile button experience.
 *
 * @param props
 */
const UserProfileButton: React.FC<IUserProfileProps> = (props) => {
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const companySvc = useRef(appStateService.company.service.get());
	const user = useSelector(UserSelectors.selectUserProfile);
	const companies = useSelector(CompanySelectors.getCompanies);
	const appConfirmLogoffText = useTranslate("app.bar.button.logoff.confirm");
	const userProfileButtonText = useTranslate(
		"global.userProfile.button.profile"
	);
	const signOutButtonText = useTranslate("global.userProfile.button.signOut");
	const profileInitials = useMemo(
		() =>
			user?.name
				.split(" ")
				.map((n) => n[0])
				.join("")
				.slice(0, 2) ?? "An",
		[user.name]
	);

	const getCompanyName = useCallback(
		(companyId: string) => {
			const company = companies.find((c) => c.id === companyId);

			return company?.name ?? companyId;
		},
		[companies]
	);

	const companyOptions = useMemo(
		() =>
			user.companies.map(
				(company) =>
					({
						text: getCompanyName(company),
						key: company
					} as IDropdownOption<IOption>)
			),
		[user, getCompanyName]
	);

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	function onClickUserProfileButton(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		event.preventDefault();

		appStateService.user.setProfileFormOpen(true);
		toggleDropdown();
	}

	async function onClickSignOutButton(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		event.preventDefault();

		// TODO: Improve this to use the ConfirmDialog component
		if (window.confirm(appConfirmLogoffText)) {
			await appStateService.auth.logOff();
			// navigate(Routes.Login);
		}
	}

	/**
	 * Handles the company switch event.
	 *
	 * @param event
	 * @param option
	 */
	async function onCompanySwitch(
		event: React.FormEvent<HTMLDivElement>,
		option: IDropdownOption<IOption> | IDropdownOption<IOption>[]
	) {
		const selectedOption = Array.isArray(option) ? option[0] : option;

		await appStateService.user.setActiveCompany(
			selectedOption.key.toString()
		);
	}

	// Ensure the user companies are loaded for the display in the dropdown
	useEffect(() => {
		companySvc.current.ensureLoadedUserCompanies();
	}, [companySvc]);

	return (
		<div className="user-profile">
			<button
				onClick={toggleDropdown}
				className="profile-button"
				data-tid={AppBarTestIds.UserProfileButton}
			>
				{user.pictureURL ? (
					<img
						src={user.pictureURL}
						alt={`User Profile: ${user.name}`}
						title={`User Profile: ${user.name}`}
						className="profile-pic"
					/>
				) : (
					<span className="profile-initials">{profileInitials}</span>
				)}
			</button>
			{isDropdownOpen && (
				<Overlay open={isDropdownOpen} onClick={toggleDropdown}>
					<div
						data-tid={AppBarTestIds.ContextMenu}
						className="dropdown"
					>
						<button
							onClick={(event) => onClickUserProfileButton(event)}
						>
							{userProfileButtonText}
						</button>
						<div
							className="combo-box-padding"
							// data-tid={AppBarTestIds.UserLogoutButton}
						>
							<Dropdown
								hideEmptyOption
								selectedKey={user.activeCompany ?? ""}
								onChange={(event, option) =>
									onCompanySwitch(event, option)
								}
								options={companyOptions}
							/>
						</div>
						<button
							onClick={(event) => onClickSignOutButton(event)}
							data-tid={AppBarTestIds.UserLogoutButton}
						>
							{signOutButtonText}
						</button>
					</div>
				</Overlay>
			)}
		</div>
	);
};

export { UserProfileButton, IUserProfileProps };
