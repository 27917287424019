import React from "react";

import { AppRouter } from "components/router/AppRouter";
import { Header } from "components/shared/header/Header";
import { HeaderSpacer } from "components/shared/header";
import { IndependentComponents } from "components/shared/layer";

import "./AppLayout.scss";

interface IRouterFrame {
	children: React.ReactNode;
}

/**
 * Header spacer component
 */
function RouterFrame(props: IRouterFrame) {
	return <div className="router-frame">{props.children}</div>;
}

/**
 * Renders the basic structure of the React application,
 * Notice the className defines layout major styles, from imported AppLayout.css.
 */
export function AppLayout() {
	return (
		<div className="App">
			{/* Begin: Menu - Fixed part of the app */}
			<Header />
			{/* End: Menu - Fixed part of the app */}
			{/* Begin: Moving part of the app */}
			<HeaderSpacer />
			<RouterFrame>
				<AppRouter />
			</RouterFrame>
			{/* End: Moving part of the app */}
			{/* Begin: Grouping free components in the app */}
			<IndependentComponents />
			{/* End: Grouping free components in the app */}
		</div>
	);
}
