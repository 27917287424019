export const dictionary = {
	"app.authentication.request.message": "Por favor, autentique-se.",
	"app.title": "BeeBase",
	"app.description":
		"BeeBase é uma empresa Brasileira de Experiências para a cabeça.",
	"app.welcome": "Bem-vindo à BeeBase!",
	"app.bar.title": "BeeBase Admin",
	"app.bar.button.logoff": "Sair",
	"app.bar.button.logoff.confirm": "Tem certeza que deseja sair?",
	"global.dropdown.defaultItem.placeholder": "<Nenhum>",
	"global.loading": "Carregando...",
	"global.logout": "Sair",
	"global.login": "Entrar",
	"global.list.action.add": "Adicionar",
	"global.list.action.edit": "Editar",
	"global.list.action.save": "Gravar",
	"global.list.action.cancel": "Cancelar",
	"global.list.action.delete": "Remover",
	"global.list.empty": "Nenhum item para exibir.",
	"global.list.count.empty": "Nenhum item",
	"global.list.count.single": "1 item",
	"global.list.count.plural": "${count} itens",
	"global.list.filter.placeholder": "Para filtrar, escreva aqui..",
	"global.userProfile.button.profile": "Perfil",
	"global.userProfile.button.signOut": "Sair",
	"list.categories.name": "Categorias",
	"list.products.name": "Produtos",
	"list.brands.name": "Marcas",
	"page.home.title": "BeeBase Admin",
	"page.home.welcome": "Bem-vindo, ${username}.",
	"page.login.title": "BeeBase Admin",
	"page.login.photoCredit": "Foto by ${author}",
	"page.login.form.title": "Login",
	"page.login.form.email": "E-mail",
	"page.login.form.empty.mail": "Por favor, informe o e-mail.",
	"page.login.form.password": "Senha",
	"page.login.form.passwordResetEmailSent": "E-mail de recuperação enviado.",
	"page.login.form.resetPassword": "Esqueci minha senha",
	"page.login.form.submit": "Entrar",
	"page.login.form.submit.passwordResetEmail": "Enviar e-mail de recuperação",
	"page.login.form.submit.error.invalidMail":
		"E-mail inválido. Por favor, verifique-o.",
	"page.login.form.submit.error.mailNotFound":
		"E-mail não encontrado. Por favor, verifique-o.",
	"page.login.form.submit.error":
		"Usuário e/ou senha inválido(s). Verifique e tente novamente.",
	"page.login.form.submit.error.offline":
		"Você está offline. Por favor, reconecte-se, então tente novamente.",
	"page.categoriesList.title": "Categorias",
	"page.clientOrderList.title": "Pedidos (Clientes)",
	"page.productsList.title": "Produtos",
	"page.suppliersList.title": "Fornecedores",
	"page.brandsList.title": "Marcas",
	"page.landingMails.title": "E-mails (Página Landing)",
	"page.stockList.title": "Estoque de Produtos",
	"page.home.chat-shortcut": "Iniciar Chat com a Bee AI",
	"page.orderList.items.count": "${count} item(ns).",
	"page.order.internal.pageTitle.new": "Criar Pedido",
	"page.order.internal.pageTitle.edit": "Visualizar Pedido",
	"page.order.management.list.empty": "Nenhum pedido aqui",
	"page.order.management.order.item.action.idClick":
		"Ative para navegar aos detalhes do pedido",
	"abbrev.unit": "un",
	"general.notAvailable": "N/D",
	"order.list.status.new":
		"Este pedido foi recém criado. Aguardando ação do cliente.",
	"order.list.status.needsPayment":
		"Este pedido está aguardando pagamento pelo cliente.",
	"order.list.status.confirmed":
		"O pagamento deste pedido foi confirmado. Processando movimentações de pagamento e estoque.",
	"order.list.status.pending":
		"Este pedido foi pago, confirmado, portanto está aguardando movimentação interna.",
	"order.list.status.inProgress":
		"Este pedido está em andamento, mas ainda não foi empacotado para envio.",
	"order.list.status.packaging":
		"Este pedido está sendo empacotado para envio.",
	"order.list.status.readyToDeliver":
		"Este pedido está pronto para entrega, portanto, aguarda um entregador.",
	"order.list.status.inDelivery":
		"Este pedido está sendo entregue por ${deliveryPersonName}.",
	"order.list.status.delivered":
		"Este pedido foi entregue com sucesso. Aguarda confirmação pelo cliente.",
	"order.list.status.completed": "Este pedido foi finalizado com sucesso.",
	"global.modal.message.action.cancel": "Cancelar",
	"global.modal.message.action.confirm": "Ok",
	"global.modal.message.default.title": "Aviso",
	"global.button.confirm": "Confirmar",
	"global.button.cancel": "Cancelar",
	"global.button.save": "Salvar",
	"global.button.close": "Fechar",
	"page.order.internal.checkout.criticalError":
		"Cancelado: Erro crítico ao criar o checkout",
	"page.order.internal.checkout.success": "Pedido criado com sucesso",
	"page.order.internal.modalTitle.new": "Novo Pedido",
	"page.order.internal.modalTitle.edit": "Pedido ${orderId}"
};
