import React from "react";

import "./Overlay.scss";

/**
 * Overlay props contract interface.
 */
interface IOverlayProps {
	open?: boolean;
	children: React.ReactNode;
	className?: string;
	onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

/**
 * Overlay shared component, for using as an obscure fallback to components displaying over the others.
 */
function Overlay(props: IOverlayProps) {
	return !!props?.open ? (
		<>
			<div className="overlay_content">{props.children}</div>
			<div
				className={`overlay ${props?.className ?? ""}`}
				onClick={props.onClick}
			></div>
		</>
	) : null;
}

export { Overlay, IOverlayProps };
