import { useCallback, useRef } from "react";

/**
 * This hook allows you to debounce any fast-clicking actions
 *
 * @param callback The callback to be executed after the delay.
 * @param delay [optional] The delay in milliseconds to debounce the click.
 *
 * @returns The debounced click function, wrapper applied.
 */
const useDebouncedClick = (
	callback: (event?: undefined | any) => any,
	delay: number = 300
) => {
	const debouncing = useRef<NodeJS.Timeout>();

	const debouncedClick = useCallback(
		(event: any) => {
			if (debouncing.current) {
				clearTimeout(debouncing.current);
			}
			debouncing.current = setTimeout(() => {
				callback(event);
			}, delay);
		},
		[callback, delay]
	);

	return debouncedClick;
};

export { useDebouncedClick };
