import React from "react";

import { IInputBaseProps, InputBase } from "./InputBase";

/**
 * InputRadio props contract interface.
 */
interface IInputRadioProps extends IInputBaseProps {}

/**
 * InputRadio shared component.
 *
 * @param props IInputRadioProps
 * @returns
 */
function InputRadio(props: IInputRadioProps): JSX.Element {
	return (
		<InputBase
			type="radio"
			checked={props.checked}
			onChange={props.onChange}
			id={props.id ?? undefined}
			disabled={props.disabled ?? undefined}
			{...props}
		/>
	);
}

export { InputRadio, IInputRadioProps };
