import React from "react";

import { useSelector } from "react-redux";
import { AppConfigSelectors, UserSelectors } from "redux/selectors";

import { UserProfileForm } from "components/shared/form/user/profile/UserProfileForm";
import { Loader } from "components/shared/loader";
import { MessageModal } from "../modal/message/MessageModal";
import { useMessageModal } from "hooks/index";
import { appStateService } from "App";

/**
 * Grouping free components in the app,
 * which are not part of the main structure.
 *
 * @returns React.FC
 */
const IndependentComponents: React.FC = () => {
	/* Global Loader management */
	const showLoader = useSelector(AppConfigSelectors.getLoading);
	const loaderText = useSelector(AppConfigSelectors.getLoadingText);
	/* Auth layer control */
	const authenticated = useSelector(UserSelectors.selectAuthenticated);
	/* Whether the user profile form is open for edit */
	const isUserProfileFormOpen = useSelector(
		UserSelectors.selectUserProfileFormOpen
	);
	const messageModalProps = useMessageModal(
		() => {
			appStateService.appManager.hideMessage();
		}
		// , () => {}
	);

	// TODO: Create authenticated layer insulation.

	return (
		<>
			{/* Start: Global non-Authenticated layer */}
			<Loader open={showLoader} text={loaderText} />
			<MessageModal
				open={messageModalProps.open}
				type={messageModalProps.type}
				title={messageModalProps.title}
				text={messageModalProps.text}
				onConfirm={messageModalProps.confirm}
				onCancel={messageModalProps.cancel}
			/>
			{/* End: Global non-Authenticated layer */}
			{/* Start: Authenticated-only layer */}
			{authenticated ? (
				<UserProfileForm open={isUserProfileFormOpen} />
			) : null}
			{/* End: Authenticated-only layer */}
		</>
	);
};

export { IndependentComponents };
