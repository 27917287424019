import { IBrand } from "models/index";
import { actions } from "redux/reducers/brands/reducer";
import { AIModels } from "services/openai";
import { ReduxStoreService } from "services/redux";
// import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";

/**
 * Class for managing the basics of the Brand data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class BrandService extends ReduxStoreService<IBrand> {
	constructor() {
		super("brands", actions.setList, actions.setQueried);
	}

	/**
	 * Generates an AI-based description of a brand, by its known name.
	 * E.g. "Nike" -> will produce something like -> "Nike is a brand of shoes and sportswear."
	 *
	 * @param brandName The name of the brand to be described.
	 * @returns A promise with the description.
	 */
	async getAIDescription(brandName: string): Promise<string> {
		return await AIModels.brandDescription.executor(brandName);
	}
}

export { BrandService };
