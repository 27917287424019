import { HttpMethod } from "enums";
import { LazyService } from "models/service/LazyService";

/**
 * Handles the interaction with SumUp API.
 * Middleware which authenticates to the SumUp API
 * and provides the necessary methods to interact with it.
 *
 * Handles the authentication during service start-up,
 * as well as during cool-down.
 *
 * Can handle API calls for returning the payment status,
 * as well as for initiating a payment. Also, includes the refund functionality.
 */
export class SumUpPaymentLazyService extends LazyService<SumUpPaymentLazyService> {
	private token: string;
	private baseURL: string = "https://api.sumup.com";
	private apiName: string = "SumUp API";

	constructor() {
		super(SumUpPaymentLazyService);
		// TODO: Obtain auth from env variables
	}

	getToken() {
		return process.env.REACT_APP_SUMUP_KEY;
	}

	getHeaders() {
		return {
			Authorization: `Bearer ${this.getToken()}`
		};
	}

	async fetchEndpoint(
		serviceEndpoint: string,
		method: string = HttpMethod.GET,
		body: any = undefined
	) {
		return await fetch(`${this.baseURL}/${serviceEndpoint}`, {
			method: method,
			headers: this.getHeaders(),
			...(!body ? {} : { body: JSON.stringify(body) })
		}).then(async (response) => {
			if (!response.ok) {
				let content = await response.json();

				if (Array.isArray(content)) {
					content = content
						.map((errorEntry) =>
							typeof errorEntry === "string"
								? errorEntry
								: `${errorEntry.param}: ${errorEntry.message}`
						)
						.join(", ");
				}

				throw new Error(
					`${this.apiName}: ${response.status} - ${content}`
				);
			}

			return await response.json();
		});
	}

	async authorize() {
		// using GET to https://api.sumup.com/authorize
		// with the following headers:
		// Authorization: Bearer REACT_APP_SUMUP_KEY
		// Content-Type: application/x-www-form-urlencoded
		// Body: grant_type=client_credentials

		return await this.fetchEndpoint(`me`);
	}

	async getMerchantCode() {
		return "MELH3P7M";
	}

	async getMerchantName() {
		return "BTS Financeira";
	}

	async getMerchantMail() {
		return "zxxobcuoyfzdkdhgwb@ckptr.com";
	}

	async getPaymentMethods() {
		return this.fetchEndpoint(
			`v0.1/merchants/${this.getMerchantCode()}/payment-methods`
		);
	}

	async getCheckouts() {
		return this.fetchEndpoint(`v0.1/checkouts`);
	}

	async getCheckout(checkoutId: string) {
		return this.fetchEndpoint(`v0.1/checkouts/${checkoutId}`);
	}

	async getTransaction(transactionId: string) {
		return this.fetchEndpoint(`v0.1/me/transactions?id=${transactionId}`);
	}

	async getTransactionHistory() {
		return this.fetchEndpoint(`v0.1/me/transactions/history`);
	}

	async getReceipt(transactionId: string) {
		return this.fetchEndpoint(`v1.1/receipts/${transactionId}`);
	}

	async createCheckout(checkout: any) {
		return this.fetchEndpoint(`v0.1/checkouts`, HttpMethod.POST, checkout);
	}

	async processCheckout(checkoutId: string) {
		return this.fetchEndpoint(
			`v0.1/checkouts/${checkoutId}`,
			HttpMethod.PUT
		);
	}

	async deactivateCheckout(checkoutId: string) {
		return this.fetchEndpoint(
			`v0.1/checkouts/${checkoutId}`,
			HttpMethod.DELETE
		);
	}

	async refundTransaction(transactionId: string) {
		return this.fetchEndpoint(
			`v0.1/me/refund/${transactionId}`,
			HttpMethod.POST
		);
	}
}
