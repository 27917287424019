import { ISliceList } from "interfaces/slices";
import { IClientOrder } from "models/order";

/**
 * Client Order Redux slice interface.
 */
export interface IClientOrderListSlice extends ISliceList<IClientOrder> {}

/**
 * Client Order Listing initial state.
 */
export const clientOrderListInitialState: IClientOrderListSlice = {
	list: [],
	loading: false,
	queried: false,
	editing: false,
	editingList: []
};
