import { ContactType } from "enums";
import { Timestamp } from "firebase/firestore";
import { IFirebaseItem } from "interfaces/index";
import { ISliceList } from "interfaces/slices";

/**
 * Supplier Redux slice interface.
 */
interface SupplierState {
	suppliers: Supplier[];
	editingSupplier: Supplier | null;
}

/**
 * System Person Contact basic interface.
 */
interface IContact {
	type: ContactType;
	value: string;
}

/**
 * Supplier basic interface.
 */
interface ISupplier extends IFirebaseItem {
	name: string;
	addressFirstLine: string;
	addressNumber: string;
	addressSecondLine: string;
	postalCode: string;
	country: string;
	state: string;
	city: string;
	contacts: IContact[];
}

/**
 * Supplier Redux slice interface.
 */
interface ISupplierSlice extends ISliceList<ISupplier> {}

/**
 * Supplier factory class.
 */
class Supplier implements ISupplier {
	id: string = "";
	name: string = "";
	addressFirstLine: string = "";
	addressNumber: string = "";
	addressSecondLine: string = "";
	postalCode: string = "";
	country: string = "";
	state: string = "";
	city: string = "";
	contacts: IContact[] = [];
	deleted: boolean = false;
	timestamp: Timestamp;
	lastChangedBy: string = "";

	constructor(data?: undefined | ISupplier) {
		this.id = data?.id ?? "";
		this.name = data?.name ?? "";
		this.addressFirstLine = data?.addressFirstLine ?? "";
		this.addressNumber = data?.addressNumber ?? "";
		this.addressSecondLine = data?.addressSecondLine ?? "";
		this.postalCode = data?.postalCode ?? "";
		this.country = data?.country ?? "";
		this.state = data?.state ?? "";
		this.city = data?.city ?? "";
		this.contacts = data?.contacts ?? [];
		this.deleted = data?.deleted ?? false;
		this.timestamp = data?.timestamp ?? Timestamp.now();
		this.lastChangedBy = data?.lastChangedBy ?? "";
	}
}

/**
 * Initial state for the supplier slice.
 */
const supplierInitialState: ISupplierSlice = {
	list: [],
	queried: false,
	loading: false,
	editing: false,
	editingList: []
};

export {
	ISupplierSlice,
	ISupplier,
	Supplier,
	SupplierState,
	supplierInitialState
};
