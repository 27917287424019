import { AIModelProcessState } from "enums";
import { Timestamp } from "firebase/firestore";
import { IAIModelProcess, IAIModelProcessUsage } from "models/index";
import { actions } from "redux/reducers/ai/model/reducer";
import { CompanySegment } from "services/companies/CompanySegment";
import { OpenAIModelVersion } from "services/openai";

/**
 * Class for managing the basics of a company's AI Model processing data in DB.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyAIModelProcessService extends CompanySegment<IAIModelProcess> {
	constructor(companyId: string) {
		super(
			companyId,
			"aiModelProcesses",
			actions.setList,
			actions.setQueried
		);
	}

	/**
	 * Logs a new model process.
	 *
	 * @param input The input to be processed
	 * @param owner The owner of the model process
	 * @param modelId The internal model ID being used
	 * @param openAIModelId The OpenAI model ID being used
	 * @returns The model process data
	 */
	async logModelProcess(
		input: string,
		owner: string,
		modelId: string,
		openAIModelId: OpenAIModelVersion
	): Promise<IAIModelProcess> {
		const modelProcessData: IAIModelProcess = {
			id: "",
			state: AIModelProcessState.New,
			modelId,
			openAIModelId,
			owner,
			input,
			output: "",
			error: "",
			processingStarted: null,
			processingCompleted: null,
			usage: {
				prompt_tokens: 0,
				completion_tokens: 0,
				total_tokens: 0
			},
			timestamp: Timestamp.now(),
			deleted: false
		};

		await this.createItem(modelProcessData);

		return modelProcessData;
	}

	/**
	 * Logs the model process as processing.
	 *
	 * @param modelProcessData The model process data to be updated.
	 * @returns The model process data
	 */
	async logModelProcessProcessing(
		modelProcessData: IAIModelProcess
	): Promise<IAIModelProcess> {
		// Advances to processing state immediately
		modelProcessData.state = AIModelProcessState.Processing;
		modelProcessData.processingStarted = Timestamp.now();
		await this.updateItem(modelProcessData);

		return modelProcessData;
	}

	/**
	 * Logs the model process as completed.
	 *
	 * @param modelProcessData The model process data to be updated.
	 * @param output The output/response of the model process.
	 * @param usage The usage data of the model process.
	 *
	 * @returns The model process data
	 */
	async logModelProcessCompletion(
		modelProcessData: IAIModelProcess,
		output: string,
		usage: IAIModelProcessUsage
	): Promise<IAIModelProcess> {
		// Advances to processed state immediately
		modelProcessData.state = AIModelProcessState.Completed;
		modelProcessData.output =
			typeof output === "string" ? output : JSON.stringify(output);
		modelProcessData.processingCompleted = Timestamp.now();
		modelProcessData.usage = usage;
		await this.updateItem(modelProcessData);

		return modelProcessData;
	}

	/**
	 * Logs the model process as errored.
	 *
	 * @param modelProcessData The model process which encountered errors
	 * @param error The error that was encountered
	 * @returns The model process data
	 */
	async logModelProcessError(
		modelProcessData: IAIModelProcess,
		error: Error
	): Promise<IAIModelProcess> {
		// Sets an error state to the entry processing
		modelProcessData.state = AIModelProcessState.Error;
		modelProcessData.error = JSON.stringify(error);
		modelProcessData.processingCompleted = Timestamp.now();
		await this.updateItem(modelProcessData);

		return modelProcessData;
	}
}

export { CompanyAIModelProcessService };
