import { IDropdownOption } from "@fluentui/react";
import { createSelector } from "@reduxjs/toolkit";
import { IOption } from "components/shared/dropdown";

import { IStock } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Stock List.
 */
export const StockListSelectors = {
	getStockList: (state: RootState) => state.stockList.list,
	getStockDropdownList: createSelector(
		(state: RootState): IDropdownOption<IOption>[] =>
			state.stockList.list?.map((stockItem: IStock) => ({
				text: stockItem.productSku,
				key: stockItem.id
			})),
		(list: IDropdownOption<IOption>[]) => list
	),
	getStockById: (state: RootState, id: string) =>
		state.stockList.list.find((stockItem: IStock) => stockItem.id === id),
	getStockListLoading: (state: RootState) => state.stockList.loading,
	getStockListQueried: (state: RootState) => state.stockList.queried,
	getIsEditing: (state: RootState) => state.stockList.editing,
	getStockListEditing: (state: RootState) => state.stockList.editingList
	// getStockListError: (state: RootState) => state.stockList.error
};
