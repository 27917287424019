import { ILazyService } from "interfaces/services";

/**
 * Lazy Starter-based service class instance creator.
 */
class LazyServiceStarter<T> implements ILazyService<T> {
	_serviceStarter: null | (() => T) = null;
	_serviceInstance: null | T = null;
	_startUpTime: null | Date = null;

	constructor(starter: () => T) {
		this._serviceStarter = starter;
	}

	/**
	 * Starts a service.
	 */
	start(): Promise<void> {
		this._startUpTime = new Date();
		return Promise.resolve();
	}

	/**
	 * Stops a service.
	 */
	stop(): Promise<void> {
		this._startUpTime = null;
		return Promise.resolve();
	}

	/**
	 * Gets the service instance.
	 * @returns The service instance.
	 */
	get(): T {
		this._serviceInstance = this._serviceStarter();

		return this._serviceInstance;
	}
}

export { LazyServiceStarter };
