import React from "react";

import { Button } from "components/shared/button";

import "components/shared/button/shared/Button.scss";

/**
 * Contract for the properties of the RemoveButton component.
 */
interface IRemoveButtonProps {
	text: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

/**
 * RemoveButton Component, which in fact includes a remember
 * mechanism to avoid accidental classNames assigned.
 *
 * @param props
 * @returns
 */
const RemoveButton: React.FC<IRemoveButtonProps> = (props) => {
	return <Button {...props} className="remove" />;
};

export { RemoveButton, IRemoveButtonProps };
