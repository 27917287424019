import React, { useMemo } from "react";

import { IInputBaseProps, InputBase } from "./InputBase";

/**
 * InputValue props contract interface.
 */
interface IInputFileProps extends IInputBaseProps {
	onReadBase64?: (base64: string) => Promise<void>;
	multiple?: undefined | boolean;
	accept?: undefined | string;
}

/**
 * InputValue shared component.
 *
 * @param props IInputValueProps
 * @returns
 */
const InputFile: React.FC<IInputFileProps> = (props) => {
	const resolvedMultiple = useMemo(
		() => props?.multiple ?? false,
		[props.multiple]
	);
	const resolveFileBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = reject;
		});

	return (
		<InputBase
			type="file"
			value={props.value}
			onChange={(event) => {
				props.onChange(event);

				if (typeof props.onReadBase64 === "function") {
					resolveFileBase64(event.target.files[0]).then(
						props.onReadBase64
					);
				}
			}}
			id={props.id ?? undefined}
			disabled={props.disabled ?? undefined}
			multiple={resolvedMultiple}
			{...props}
		/>
	);
};

export { InputFile, IInputFileProps };
