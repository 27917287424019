/**
 * Contract for structuring the properties of the image metadata.
 */
export interface IImageMetadata {
	src: string;
	alt?: string;
	title?: string;
}

/**
 * Contract for structuring the properties of the image metadata, including
 * keeping the information of its author.
 */
export interface IProtectedImageMetadata extends IImageMetadata {
	author: string;
	authorLink: string;
}

/**
 * Contract for structuring the properties of the image provider.
 */
export interface IImageProvider {
	Icon: {
		brands: {
			instagram: string;
			facebook: string;
			whatsapp: string;
		};
	};
	Main: {
		logo: { encoded: IImageMetadata; decoded: IImageMetadata };
	};
	Landing: {
		background: IProtectedImageMetadata;
	};
	Login: {
		background: IProtectedImageMetadata;
	};
}

/**
 * Keeps a reference and the resolvers for all the images in the app.
 * It provides image based on their own implementation,
 * however, the solution is centralized.
 *
 * TODO: Implement dynamic image loading, based on the use of Firebase Storage mechanism.
 */
export const AppImageProvider: IImageProvider = {
	Icon: {
		brands: {
			instagram: "/img/icon/instagram-logo.png",
			facebook: "/img/icon/facebook-logo.png",
			whatsapp: "/img/icon/whatsapp-logo.png"
		}
	},
	Main: {
		logo: {
			encoded: {
				alt: "Logo da BeeBase Store",
				src: "/img/brand/BeeBase-logo-02-decoded-vertical-margin.png",
				title: "BeeBase Store"
			},
			decoded: {
				alt: "Logo da BeeBase",
				src: "/img/brand/BeeBase-logo-02-decoded-symbol.png",
				title: "BeeBase"
			}
		}
	},
	Landing: {
		background: {
			author: "rovenimages.com",
			authorLink:
				"https://www.pexels.com/photo/yellow-bokeh-photo-949587/",
			src: "/img/background/pexels-rovenimagescom-949587.jpg"
		}
	},
	Login: {
		background: {
			author: "Bruno Teixeira",
			authorLink: "https://instagram.com/brunoteixeirasilva",
			src: "/img/background/colonia-del-sacramento-uruguay-bruno-teixeira-filter.jpg"
		}
	}
};
