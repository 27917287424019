/**
 * Possible Order Status in this application.
 */
export enum SupplierOrderStatus {
	new = "new",
	fillOut = "fillOut",
	readyToRequest = "readyToRequest",
	requested = "requested",
	confirmed = "confirmed",
	inProgress = "inProgress",
	inDelivery = "inDelivery",
	delivered = "delivered",
	completed = "completed",
	cancelled = "cancelled",
	rejected = "rejected",
	error = "error"
}
