import { createSelector } from "@reduxjs/toolkit";
import { IClient } from "models/index";
import { RootState } from "redux/store";

/**
 * Selectors: Client.
 */
export const ClientSelectors = {
	getClients: (state: RootState) => state.clients.list,
	getClientByIds: createSelector(
		(state: RootState, itemIds: string[]) => itemIds,
		(state: RootState) => state.clients.list,
		(itemIds, clients: IClient[]) =>
			clients.filter((client) => itemIds.includes(client.id))
	),
	getClientById: createSelector(
		(state: RootState, itemId: string) => itemId,
		(state: RootState) => state.clients.list,
		(itemId, clients: IClient[]) =>
			clients.find((client) => client.id === itemId)
	),
	getClientsLoading: (state: RootState) => state.clients.loading,
	getClientsQueried: (state: RootState) => state.clients.queried,
	getIsEditing: (state: RootState) => state.clients.editing,
	getClientsEditing: (state: RootState) => state.clients.editingList
	// getClientsError: (state: RootState) => state.clients.error
};
