/**
 * Contract for the shape of the response obtained when calling the ViaCEP API.
 */
interface ICEPResponse {
	cep: string;
	logradouro: string;
	complemento: string;
	bairro: string;
	localidade: string;
	uf: string;
	ibge: string;
	gia: string;
	ddd: string;
	siafi: string;
}

/**
 * Contract for the shape of the error response obtained when calling the ViaCEP API.
 */
interface ICEPError {
	erro: boolean;
}

/**
 * Service class for the ViaCEP API.
 */
class ViaCEPService {
	baseURL: string = "https://viacep.com.br/ws";
	numberPathFormat: string = "{0}/json";

	public async getCEP(cep: string): Promise<ICEPResponse | ICEPError> {
		const response = await fetch(
			`${this.baseURL}/${this.numberPathFormat.replace(
				"{0}",
				cep.replace("-", "")
			)}`
		);
		const json = await response.json();
		return json;
	}
}

export { ViaCEPService, ICEPResponse, ICEPError };
