import { createSelector } from "@reduxjs/toolkit";
import { SortDirection } from "enums";
import { IColumnConfiguration, IdentifiedListItem } from "interfaces/index";
import { IRootState } from "redux/reducers";

export const ListSelectors = {
	getSelectedItems: (state: IRootState): string[] => state.list.selected,
	getIsItemSelected: createSelector(
		(state: IRootState, itemId: string): boolean =>
			state.list.selected.includes(itemId),
		(isSelected: boolean) => isSelected
	),
	getIsEveryItemSelected: (state: IRootState, items): boolean =>
		items.every((item) => state.list.selected.includes(item.id)),
	getFilter: (state: IRootState) => state.list.filter,
	getFilterProp: <T extends IdentifiedListItem>(
		state: IRootState
	): IColumnConfiguration<T> => state.list.filterProp,
	getSortProp: <T extends IdentifiedListItem>(
		state: IRootState
	): IColumnConfiguration<T> => state.list.sortProp,
	getSortDirection: (state: IRootState): SortDirection =>
		state.list.sortDirection === "asc"
			? SortDirection.ASC
			: SortDirection.DESC,
	getIsEditing: (state: IRootState): boolean => state.list.editing
};
