import React, { useMemo } from "react";
import { useTranslateFn } from "hooks/i18n";

import { Overlay } from "components/shared/overlay";
import { useDebouncedClick, useEnterKeyDown, useMessageModal } from "hooks";

import "components/shared/modal/Modal.scss";
import "components/shared/modal/message/MessageModal.scss";
import { MessageModalType } from "enums";

/**
 * Determines the contractual requirements to use the MessageModal component.
 */
export interface IMessageModalProps {
	/**
	 * Determines whether the modal is open or not.
	 */
	open: boolean;
	/**
	 * The type of message modal to display.
	 */
	type: MessageModalType;
	/**
	 * The message to display.
	 */
	text: string;
	/**
	 * The title of the modal.
	 */
	title: string;
	/**
	 * The function to call when the confirm button is clicked.
	 */
	onConfirm: () => void;
	/**
	 * The function to call when the cancel button is clicked.
	 */
	onCancel: undefined | (() => void);
}

/**
 * MessageModal component to render screen-centered message modals.
 *
 * @param props
 */
export const MessageModal: React.FC<IMessageModalProps> = (props) => {
	const messageModalId = "app-message-modal";
	const translate = useTranslateFn();
	const texts = {
		button: {
			cancel: translate("global.modal.message.action.cancel"),
			confirm: translate("global.modal.message.action.confirm")
		}
	};
	const messageModalState = useMessageModal(props.onConfirm, props.onCancel);
	const resolvedModalType = useMemo(() => {
		if (props?.type === MessageModalType.error) {
			return "error";
		} else if (props?.type === MessageModalType.warning) {
			return "warning";
		} else if (props?.type === MessageModalType.success) {
			return "success";
		} else {
			return "info";
		}
	}, [props.type]);

	useEnterKeyDown(() => {
		messageModalState.confirm();
	});

	return props.open ? (
		<div className="modal" id={messageModalId}>
			<div
				className={`message-modal ${resolvedModalType} ${
					props.open ? "open" : ""
				}`}
			>
				{/* <div className="modal__container"> */}
				<div className="message-modal-title">{props.title}</div>
				<div className="message-modal-content">{props.text}</div>
				<div className="message-modal-actions">
					{typeof messageModalState.cancel === "function" ? (
						<button
							className="message-modal-button modal__button_cancel"
							onClick={messageModalState.cancel}
						>
							{texts.button.cancel}
						</button>
					) : null}
					<button
						className="message-modal-button modal__button_confirm"
						onClick={messageModalState.confirm}
					>
						{texts.button.confirm}
					</button>
				</div>
				{/* </div> */}
			</div>
			<div
				className="modal__backdrop"
				onClick={messageModalState.hide}
			></div>
		</div>
	) : null;
};
