import { ICategory } from "models/index";
import { actions } from "redux/reducers/categories/reducer";
import { CompanySegment } from "services/companies/CompanySegment";
// import { FirebaseServiceHandler } from "services/firebase/FirebaseServiceHandler";

const _baseStoragePath = (brandId: string) => `categories/${brandId}`;

/**
 * Class for managing the basics of the Category data.
 * Manages the Redux store, as well as the Firebase database modifications.
 * Also, registers logs of the operations.
 */
class CompanyCategoryService extends CompanySegment<ICategory> {
	constructor(companyId: string) {
		super(companyId, "categories", actions.setList, actions.setQueried);
	}
}

export { CompanyCategoryService };
